@import "./../../styles/app-constant.scss";
@import "./../../styles/app-variables.scss";
@import "./../../styles/mixin.scss";

.welcome-wrapper {
  .popup-modal {
    border-radius: 20px;
    padding: 0 0 15px;
    max-width: 350px;
    @include breakpoint(xs) {
      min-width: 90%;
      width: 90%;
    }
    margin: 0 auto;
    box-shadow: $grayColor02 0px 0px 40px;
    .close-btn {
      button {
        background: transparent;
        border: 1px solid $grayColor02;
        font-size: 0.875rem;
        padding: 10px 30px;
        line-height: initial;
      }
    }
  }
}

.popup-welcome {
  p {
    margin-bottom: 0;
  }

  .popup-welcome-title {
    padding: 15px 0;
    font-weight: bold;
    text-align: center;
    font-size: 1.125rem;
    color: $blackColor;
  }

  .item-welcome {
    width: 100%;
    text-align: center;
    .intro-img {
      @include breakpoint(xs) {
        max-width: 100%;
        width: 80px;
      }
    }
    .txt-intro {
      padding: 15px 0;
      line-height: initial;
      &.intro-quality {
        color: $primaryColor;
      }
      &.intro-sustainability {
        color: $AquaColor;
      }
      &.intro-diversity {
        color: $errorColor;
      }
    }
  }

  .intro-message {
    text-align: center;
    padding: 5px 10px 20px;
    font-size: 0.875rem;
  }
}
