@import "./../../styles/app-constant.scss";
@import "./../../styles/app-variables.scss";
@import "./../../styles/mixin.scss";
.main-header .homepage-header {
  background: unset !important;
}

.main-header {
  box-shadow: 0 1px 6px 0 #20212447;
  position: relative;
  padding: 5px 0;
  z-index: 1;
  &:not(.homepage-header) {
    background: #ffffff;
    .product-search-input {
      border: 1px solid #dfdfdf !important;
    }
  }
  .navbar-toggler {
    & > img {
      width: 30px;
      height: 30px;
      filter: invert(0.5);
    }
  }
  .navUl {
    & > li {
      & > a,
      & > p {
        padding-top: 0px;
        padding-bottom: 0px;
        color: $blackColor !important;
        font-size: 1.125rem;
        @include down(1250px) {
          font-size: 1rem;
        }
        @include down(1076px) {
          font-size: 0.8rem !important;
        }
        cursor: pointer;
        &.active,
        &:hover {
          color: $primaryColor !important;
          text-decoration: none;
        }
      }
      @include up(992px) {
        &:not(:first-child) {
          & > a,
          & > p {
            border-left: 1px solid rgba(#000000, 0.1);
          }
        }
      }
    }
  }

  .option-header {
    font-size: 0.75rem;
    padding: 10px 10px 5px;
    font-weight: bold;
    border-bottom: 1px solid $WhiteColor;
    margin-bottom: -1px;
    position: relative;
    background: $WhiteColor;
  }
  .item-option {
    background: $WhiteColor;
    a {
      padding: 10px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      display: block;
      color: $blackColor01;
      line-height: initial;
      font-size: 0.875rem;
      &:hover {
        background: $blueColor01;
        text-decoration: none;
      }
    }
  }

  .product-search-new {
    width: 100%;
    max-width: 350px;
    position: relative;
    @include down(1200px) {
      max-width: 300px !important;
    }
    .product-loading {
      padding: 15px;
      display: block;
      font-size: 0.875rem;
    }
    .btn-header-search {
      background: rgba(#000000, 0.05);
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 50px;
      border-radius: 0 5px 5px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .product-search-input {
      width: 100%;
      border: 0;
      height: 50px;
      padding: 0 50px 0 15px;
      color: rgb(34, 47, 62);
      border-radius: 5px;
      &:focus-visible {
        outline: 0;
      }
    }
    .product-search-list {
      background: $WhiteColor;
      border-radius: 5px;
      box-shadow: #32325d1a 0px 7px 14px 0px, #00000012 0px 3px 6px 0px;
      margin-top: 5px;
      position: absolute;
      width: calc(100% - 25px);
      z-index: 3;
      max-height: 85vh;
      overflow-y: auto;
      .integrants-name {
        font-size: 0.75rem;
        padding: 10px 10px 5px;
        font-weight: bold;
        margin-bottom: 0;
      }
      a {
        display: block;
        padding: 7px 10px;
        color: #616b74;
        border-top: 1px solid #eeeeee;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.875rem;
        &.first-item {
          border-top: 0;
        }
        &:hover {
          background: #f4f7fa;
          text-decoration: none;
        }
      }
    }
  }
  .product-search {
    padding: 0 0 0 25px;
    width: calc(100% - 20px);
    z-index: 1;
    position: relative;
    > div {
      display: block !important;
      > div {
        top: 68px !important;
        padding: 0 !important;
        border-radius: 5px !important;
        .list-product {
          padding: 10px;
        }
      }
    }
    .product-search-input {
      width: 100%;
      border: 0;
      padding: 17px 15px 15px;
      line-height: initial;
      box-shadow: #32325d1a 0px 7px 14px 0px, #00000012 0px 3px 6px 0px;
      border-radius: 5px;
      color: $blackColor;
      &::placeholder {
        font-style: italic;
        opacity: 0.4;
      }
      &:focus {
        outline: 0;
      }
    }
    .item-search {
      background: #ffffff;
      .item-category {
        padding: 10px 0 0 10px;
        font-size: 0.75rem;
        opacity: 0.5;
      }
    }
  }
}

.smGoogleTranslateEl {
  margin-right: 10px;
}
.webGoogleTranslateEl {
  margin-right: 10px;
}

@include down(992px) {
  .main-header {
    .container-fluid {
      padding-left: 15px;
      padding-right: 15px;
    }
    .webGoogleTranslateEl {
      display: none !important;
    }
    .navUl {
      margin-top: 8px;
      & > li {
        margin: 5px 0px;
        & > a,
        & > p {
          font-size: 1.125rem;
        }
      }
    }
    .product-search-new {
      max-width: 100% !important;
      margin: 10px 0;
    }
  }
}

.mobileLanDropdown {
  margin: 0px 10px 0px 0px;
  & > p {
    margin-bottom: 0px;
  }
}

.mobileLanUl {
  left: 50% !important;
  transform: translateX(-60%);
  min-width: 0px !important;
  & > li {
    padding: 5px 10px;
  }
}
