@import "./app-variables.scss";
@import "./mixin.scss";
html {
  overflow-y: scroll;
}
body {
  font-family: DM Sans, sans-serif;
  color: $primaryTextColor;
  font-size: 16px;
}

a {
  word-break: break-all;
}

.bodyMaxWidth {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  @include down(1210px) {
    padding: 0px 20px;
  }
}

#goog-gt-tt,
#goog-gt- {
  display: none !important;
}

.headline {
  color: $primaryColor;
  font-size: 4rem;
  margin-bottom: 2rem;
  font-weight: bold;
}

.address-full {
  font-size: 2rem;
}

.header-tc {
  color: $WhiteColor;
  background-attachment: fixed;
  margin-bottom: 30px;
  background-position: center bottom;
  background-color: #424242;
  .header-content {
    min-height: 50vh;
  }
  .txt-tc {
    font-size: 3rem;
    letter-spacing: 4px;
    font-weight: bold;
    @include breakpoint(xs) {
      font-size: 2rem;
      letter-spacing: normal;
      line-height: initial;
      padding-bottom: 10px;
    }
  }
  .txt-carefully {
    padding-bottom: 30px;
    font-size: 16px;
  }
  p {
    margin: 0;
  }
}

.page-tc {
  padding-bottom: 100px;
  h1 {
    font-size: 2rem;
    font-weight: bold;
    color: $primaryColor;
    border-bottom: 1px dashed $borderColor;
    padding-bottom: 10px;
    @include breakpoint(xs) {
      font-size: 1.5rem;
    }
  }
  h2 {
    padding-top: 30px;
    font-size: 1.5rem;
    font-weight: bold;
    opacity: 0.8;
  }
  a {
    color: $primaryColor;
  }
  li {
    margin-bottom: 10px;
  }
}

.page-privacy {
  padding-bottom: 100px;
}

.according {
  margin-bottom: 30px;
  border-bottom: 1px solid $borderColor;
  .Collapsible {
    border: 1px solid $borderColor;
    border-bottom: 0;
    > span {
      display: flex;
      font-weight: bold;
      padding: 12px 35px 0 0;
      position: relative;
      line-height: initial;
      &:before {
        color: $primaryColor;
        content: "Q.";
        padding: 0 10px;
      }
      &:after {
        font-family: "Material Icons";
        content: "arrow_forward_ios";
        background: $primaryColor;
        position: absolute;
        right: 10px;
        color: $WhiteColor;
        display: block;
        top: 50%;
        margin-top: -5px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 0.75rem;
        text-align: center;
        border-radius: 100%;
        transform: rotate(90deg);
      }
    }
    > div {
      display: flex;
      padding-top: 10px;
      padding-right: 30px;
      td {
        vertical-align: top;
      }
      ul {
        padding-top: 10px;
        li {
          padding-bottom: 10px;
        }
      }
      &:before {
        content: "A.";
        color: $primaryColor;
        font-weight: bold;
        padding: 0 10px;
      }
    }
    &.isOpen {
      background: $disabledColor;
      padding-bottom: 10px;
      > span {
        &:after {
          transform: rotate(-90deg);
        }
      }
    }
  }
}

.page-affiliate {
  .section-affiliate {
    //box-shadow: #cccccc 0px 20px 28px -23px;
    position: relative;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center center;
    background-attachment: fixed;
    min-height: 450px;
    padding-top: 3%;
    @include breakpoint(xs) {
      background-size: auto 100%;
    }
  }
  p {
    margin-bottom: 0;
  }
  .txt-trusted-affiliates {
    //padding: 5% 0;
    font-size: 1.5rem;
    @include breakpoint(xs) {
      font-size: 1.25rem;
    }
    @include sm {
      text-align: center;
    }
    b {
      color: $primaryColor;
    }
  }
  .strip-affiliate01 {
    &:first-child {
      border-top: 0;
    }
    border-top: 1px dashed $borderColor;
    padding: 6% 0;
    .txt {
      .btn {
        margin-top: 20px;
      }
      @include sm {
        text-align: center;
      }
    }
    .affiliates-logo {
      height: 100%;
      border-radius: 10px;
      @include breakpoint(xs) {
        &:not(.dark) {
          margin-bottom: 10px;
          padding: 0 0 20px;
          display: block !important;
        }
      }
      @include sm {
        &:not(.dark) {
          display: flex !important;
        }
      }
      img {
        //width: 150px;
        max-width: 200px;
      }
      &.dark {
        background: $blackColor;
        padding: 20px 0;
      }
    }
  }
}

.downScroll {
  position: absolute;
  bottom: 20px;
  left: 50%;
  margin-left: -12px;
  cursor: pointer;

  .m_scroll_arrows {
    display: block;
    width: 5px;
    height: 5px;
    -ms-transform: rotate(45deg);
    /* IE 9 */
    -webkit-transform: rotate(45deg);
    /* Chrome, Safari, Opera */
    transform: rotate(45deg);
    border-right: 2px solid $primaryColor;
    border-bottom: 2px solid $primaryColor;
    margin: 0 0 3px 4px;
    width: 16px;
    height: 16px;
  }

  .unu {
    margin-top: 1px;
  }

  .unu,
  .doi,
  .trei {
    -webkit-animation: mouse-scroll 1s infinite;
    -moz-animation: mouse-scroll 1s infinite;
    animation: mouse-scroll 1s infinite;
  }

  .unu {
    -webkit-animation-delay: 0.1s;
    -moz-animation-delay: 0.1s;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    animation-delay: alternate;
  }

  .doi {
    -webkit-animation-delay: 0.2s;
    -moz-animation-delay: 0.2s;
    -webkit-animation-direction: alternate;
    animation-delay: 0.2s;
    animation-direction: alternate;
    margin-top: -6px;
  }

  .trei {
    -webkit-animation-delay: 0.3s;
    -moz-animation-delay: 0.3s;
    -webkit-animation-direction: alternate;
    animation-delay: 0.3s;
    animation-direction: alternate;
    margin-top: -6px;
  }

  .mouse {
    height: 42px;
    width: 24px;
    border-radius: 14px;
    transform: none;
    border: 2px solid $primaryColor;
    top: 170px;
    box-shadow: 0 1px 6px 0 #20212447;
    background: rgba($primaryColor, 0.3);
  }

  .wheel {
    height: 5px;
    width: 2px;
    display: block;
    margin: 5px auto;
    background: $primaryColor;
    position: relative;
    height: 4px;
    width: 4px;
    border: 2px solid $primaryColor;
    -webkit-border-radius: 8px;
    border-radius: 8px;
  }

  .wheel {
    -webkit-animation: mouse-wheel 0.6s linear infinite;
    -moz-animation: mouse-wheel 0.6s linear infinite;
    animation: mouse-wheel 0.6s linear infinite;
  }

  @-webkit-keyframes mouse-wheel {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      -webkit-transform: translateY(6px);
      -ms-transform: translateY(6px);
      transform: translateY(6px);
    }
  }
  @-moz-keyframes mouse-wheel {
    0% {
      top: 1px;
    }
    25% {
      top: 2px;
    }
    50% {
      top: 3px;
    }
    75% {
      top: 2px;
    }
    100% {
      top: 1px;
    }
  }
  @-o-keyframes mouse-wheel {
    0% {
      top: 1px;
    }
    25% {
      top: 2px;
    }
    50% {
      top: 3px;
    }
    75% {
      top: 2px;
    }
    100% {
      top: 1px;
    }
  }
  @keyframes mouse-wheel {
    0% {
      top: 1px;
    }
    25% {
      top: 2px;
    }
    50% {
      top: 3px;
    }
    75% {
      top: 2px;
    }
    100% {
      top: 1px;
    }
  }
  @-webkit-keyframes mouse-scroll {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes mouse-scroll {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  @-o-keyframes mouse-scroll {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes mouse-scroll {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
}
// Common classes
.p-m-0 p{
  margin: 0;
}

.postHashTag {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cursorPointer {
  cursor: pointer;
}

.ellipsis {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.multilineEllipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.display-none {
  display: none !important;
}

.model {
  z-index: 1100 !important;
}

.textInURL {
  font-size: 16px;
  word-break: break-word;
}

.showMoreBtns {
  font-size: 14px;
  font-weight: 400;
  color: $primaryColor;
  line-height: 1.2;
  cursor: pointer;
}

// Google Translate CSS
#google_translate_element .goog-te-gadget {
  margin-right: -60px !important;
}
#google_translate_element .goog-te-combo {
  margin-right: 0px !important;
  padding: 0;
  font-size: 18px;
  line-height: 23px;
  color: $blackColor;
  font-weight: 400;
  border: none;
  background-color: transparent;
  font-family: $fontFamily;
  text-transform: capitalize;
  max-width: max-content;
  cursor: pointer;
}
.VIpgJd-ZVi9od-ORHb-OEVmcd,
.VIpgJd-ZVi9od-l4eHX-hSRGPd,
.VIpgJd-suEOdc {
  visibility: hidden !important;
}

// Mapbox GL JS CSS
.mapContainer {
  position: relative;
  height: 100%;
}