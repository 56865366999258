@import "./../../styles/app-constant.scss";
@import "./../../styles/app-variables.scss";
@import "./../../styles/mixin.scss";

.meet-the-team-root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  & > div:nth-child(2) {
    flex: 1;
  }
}

.meet-the-team-content {
  margin-bottom: 50px;
}

.mttHeader {
  height: 300px;
  background-color: $disabledColor;
  & > h1 {
    position: relative;
    border-bottom: none;
    width: max-content;
    text-align: center;
    color: $primaryColor;
    margin-bottom: 20px;
    font-size: 4rem;
    &::after {
      content: "";
      position: absolute;
      background-color: $borderColor;
      height: 6px;
      bottom: -24px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
}

.mttMainHeadings {
  text-transform: uppercase;
  text-align: center;
  color: $blackColor01;
  width: max-content;
  letter-spacing: normal;
  font-weight: bold;
  &:first-child {
    margin-top: 30px;
  }
}

.mttCardRow {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 30px;
  & > div {
    padding: 10px 10px;
  }
}

@media (min-width: 370px) {
  .mttCardRow > div:before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    bottom: 0;
    width: 20px;
    background-color: #fff;
    z-index: 9;
    pointer-events: none;
  }
  .mttCardRow > div:after {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    bottom: 0;
    width: 20px;
    background-color: #fff;
    z-index: 9;
    pointer-events: none;
  }
  .mttCardRow {
    & > div {
      padding: 10px 30px;
    }
  }
}

.swiper-pagination-bullet-active {
  background: #1e7e34 !important;
}

.mttLink {
  position: relative;
  & > span {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -35px;
    & > a > img {
      margin-left: 10px;
      color: $primaryColor;
      cursor: pointer;
      width: 25px;
      height: 25px;
      opacity: 0;
      &:hover {
        opacity: 1;
      }
      @media (max-width: 992px) {
        opacity: 1;
      }
    }
    & > a {
      height: inherit;
    }
  }
}

.mttCard {
  width: calc(100% / 3);
  box-sizing: border-box;
  margin: 30px 0px;
  & > div {
    padding: 3.5% 3.5% 0px 3.5%;
    box-sizing: border-box;
    & > div {
      display: flex;
      flex-direction: column;
      & > div {
        width: 100%;
        &:nth-child(1) {
          position: relative;
          padding-top: 100%;
          & > div {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            & > img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: top;
            }
          }
        }
        &:nth-child(2) {
          margin: 20px 0px;
          & > h3 {
            font-weight: bold;
            font-size: 1.5rem;
            color: $primaryColor;
            line-height: initial;
            display: inline-block;
            padding: 5px 0;
            &:hover {
              color: $blackColor02;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  &:hover {
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 11%);
    & > div > div > div:nth-child(2) > h3 > span > a > img {
      opacity: 1;
    }
  }
}

.meet-the-team-slider {
  .swiper-slide-mttCard {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: #1e7e34 !important;
    top: 42% !important;
  }
  .swiper-button-prev {
    left: 0px !important;
  }
  .swiper-button-next {
    right: 0px !important;
  }
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    color: #131313 !important;
  }
}

@media (max-width: 992px) {
  .mttCard {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .mttCard {
    margin: 0px auto;
    width: 80%;
  }
  .mttHeader {
    & > h1 {
      font-size: 3rem;
    }
  }
}

@media (max-width: 576px) {
  .mttLink {
    font-size: 1rem !important;
  }
}

@media (max-width: 500px) {
  .mttCard {
    width: 100%;
  }
}

.custom-btn {
  color: white !important;
  background-color: $lightPrimaryColor;
  font-weight: bold;
  line-height: 140%;
  letter-spacing: 0.2px;
  border: none;
}

.custom-btn:hover {
  background-color: #000 !important;
  color: white !important;
  border: none;
}
