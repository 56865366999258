@import "./../../styles/mixin.scss";
@import "./../../styles/app-variables.scss";
@import "./../../styles/app-constant.scss";

.transparent-bg {
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  &.loader {
    z-index: 1000;
  }
  .loader {
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid $primaryColor; /* Blue */
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

body {
  top: 0px !important;
}

body .goog-te-gadget > div {
  display: inline-flex;
}
body .goog-te-gadget .goog-te-combo {
  margin: 0;
  height: 40px;
  font-size: 14px;
  padding: 0 10px;
  border-radius: 5px;
  box-shadow: none;
  outline: none;
  margin-right: 15px;
  background-color: #fff;
}

.product-main-description {
  overflow: auto;
}
.product-batchs-root {
  width: calc(100% - 80px);
  height: 100%;
  @include sm {
    width: 100%;
    height: calc(100% - 70px);
  }
}

// Product Detail CSS
.product-detail-root {
  width: 100%;
  .mobile-product-detail-root {
    display: none;
  }
  @include sm {
    .product-main-description {
      display: none;
    }
    .mobile-product-detail-root {
      display: flex;
    }
  }
}
