@import "./../../styles/mixin.scss";
@import "./../../styles/app-variables.scss";
@import "./../../styles/app-constant.scss";

.mb10 {
  margin-bottom: 10px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.product-header {
  .notification-title {
    background: linear-gradient(90deg, #fdbc00 0%, #fdc500 38%, #df8901 100%);
    color: $WhiteColor;
    text-align: center;
    padding: 10px;
    font-size: 0.875rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-shadow: 0 0 10px yellow;
    @include breakpoint(xs) {
      line-height: initial;
    }
    .icon-notification {
      margin-right: 10px;
    }
  }
  .product-header {
    background-size: cover;
    background-position: center center;
    text-align: center;
    background-color: $blackColor01;
    .product-img {
      max-width: 100%;
      @include breakpoint(xs) {
        padding: 50px 0 0;
        margin-bottom: -50px;
      }
      @include breakpoint(sm) {
        height: 300px;
        margin-bottom: -3%;
        padding: 3% 0 0;
      }
    }
  }
}

.product-body {
  max-width: 600px;
  margin: 0 auto;
  color: $blackColor;
  @include breakpoint(xs) {
    padding: 0 15px;
  }
  p {
    margin: 0;
  }
  .product-title {
    font-size: 1.625rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1.7px;
    line-height: initial;

    @include breakpoint(sm) {
      padding: 60px 0 0;
    }
    @include breakpoint(xs) {
      line-height: initial;
      padding-top: 80px;
    }
  }

  .product-tabs {
    .tab-header {
      text-align: center;
      padding: 20px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      @include breakpoint(xs) {
        display: flex;
        overflow: scroll;
      }
      .tab-button {
        @include breakpoint(sm) {
          min-width: 150px;
          margin-left: 10px;
        }
        display: inline-block;
        border-radius: 5px;
        padding: 15px;
        cursor: pointer;
        font-weight: bold;
        font-size: 0.875rem;
        text-align: center;
        line-height: initial;
        border: 0;
        &:not(.active) {
          background: transparent !important;
        }
        &:hover {
          background: $grayColor02;
          text-decoration: none;
        }
        &:first-child {
          margin-left: 0;
        }
        &.active {
          color: $WhiteColor;
        }
      }
    }
    .tab-title {
      font-size: 1.125rem;
      font-weight: bold;
      text-transform: uppercase;
    }
    .tab-list {
      border-radius: 10px;
      background: linear-gradient(to left bottom, yellow, orange, darkorange);
      padding: 4px;
      margin: 15px 0 30px;
      .tab-list-white {
        background: $WhiteColor;
        border-radius: 8px;
        .tab-list-item {
          padding: 10px;
          font-size: 0.875rem;
          letter-spacing: 1px;
          border-top: 1px solid rgba($color: $blackColor, $alpha: 0.09);
          text-transform: capitalize;
          &:first-child {
            border-top: 0;
          }
          .icon-right {
            margin-right: 20px;
          }
          .tab-list-itemtext {
            font-weight: bold;
            width: calc(100% - 107px);
          }
        }
      }
    }
  }

  .product-integration {
    margin-bottom: 30px;
    .integration-title {
      font-size: 1.25rem;
      font-weight: bold;
      text-transform: uppercase;
      padding-bottom: 20px;
    }
    .list-integration {
      display: flex;
      justify-content: center;
      @include breakpoint(xs) {
        overflow-x: auto;
        justify-content: flex-start;
      }
      a.link-integration {
        color: initial;
        margin-left: 20px;
        &:first-child {
          margin-left: 0;
        }
        &:hover {
          text-decoration: none;
          opacity: 0.8;
        }
      }
      .item-integration {
        text-align: center;
        margin-left: 10px;
        &:first-child {
          margin-left: 0;
        }
        img.img-integration {
          border-radius: 100%;
          width: 70px;
          height: 70px;
          background: $grayColor02;
        }
        div.img-integration {
          width: 70px;
          height: 70px;
          border: 2px solid $grayColor02;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          margin: 0 auto;
          img {
            max-width: 60px;
            max-height: 60px;
          }
        }
        .title-integration {
          font-size: 10px;
          text-transform: uppercase;
          padding: 10px 0 0;
          max-width: 100px;
          line-height: initial;
          letter-spacing: 1px;
        }
      }
    }
  }

  .product-description {
    .title-description {
      font-weight: bold;
      text-transform: uppercase;
    }
    .description-description {
      padding: 16px 0 30px;
    }
  }

  .product-about {
    margin: 20px 0 60px;
    border-radius: 10px;
    border: 1px solid $grayColor02;
    font-size: 0.75rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: initial;
    .item-about {
      @include breakpoint(xs) {
        padding: 10px;
      }
      @include breakpoint(sm) {
        display: flex;
        padding: 10px 15px;
      }
      &:empty {
        display: none;
      }
      .title-about {
        font-weight: bold;
        &:after {
          content: ":";
          padding-right: 5px;
        }
      }
      &:nth-child(2n) {
        background-color: $grayColor03;
      }
    }
  }
}

.product-container {
  // height: $page-height;
  width: 100%;
  height: 100%;
  display: flex;
  // @include down(991px) {
  //   height: calc(100% - 146px);
  // }
  @include sm {
    flex-direction: column;
  }
  .product-detail-root {
    width: calc(100% - 80px);
    height: 100%;
    overflow-y: auto;
    @include sm {
      width: 100%;
      height: calc(100% - 70px);
    }
  }
  .product-right-navigation {
    width: 80px;
    box-shadow: $grayColor02 0px 0px 20px;
    @include sm {
      width: 100%;
      height: 70px;
      display: flex;
    }
    .link-rightnav {
      width: 100%;
      cursor: pointer;
      min-height: 65px;
      display: flex;
      align-items: center;
      justify-items: center;
      border-bottom: 1px solid $grayColor01;
      &:hover {
        text-decoration: none;
        & > div {
          span {
            color: $primaryColor;
          }
          .stroke-icon {
            stroke: $primaryColor;
          }
          .fill-icon {
            fill: $primaryColor;
          }
        }
      }
      &.active {
        pointer-events: none;
        cursor: none;
        background: $primaryColor;
        text-decoration: none;
        div {
          span {
            color: $WhiteColor;
          }
          .stroke-icon {
            stroke: $WhiteColor;
          }
          .fill-icon {
            fill: $WhiteColor;
          }
        }
      }
      div {
        text-align: center;
        width: 100%;
        span {
          display: block;
          font-size: 11px;
          color: $blackColor01;
          line-height: initial;
          padding: 4px 5px 0;
          @include sm {
            font-size: 14px;
          }
        }
        & > svg {
          width: 20px;
          height: 20px;
        }
        .stroke-icon {
          stroke: $blackColor01;
        }
        .fill-icon {
          fill: $blackColor01;
        }
      }
    }
  }
}
// .product-origin-container {
//   height: $page-height;
// }

.product-attachments {
  height: 100%;
  overflow-y: auto;
  background-color: $grayColor09;
  width: 100%;
  .doc-list {
    margin: 0 15px;
    padding: 15px 0 0;
    font-size: 0.875rem;
    .w-ll {
      width: 50px;
      min-width: 50px;
    }
    .doc-ll {
      padding: 0 5px;
    }
    .doc-title {
      &:not(:first-child) {
        padding-top: 15px;
      }
    }
    &:not(.active) {
      border-top: 1px solid $grayColor02;
    }
    .doc-item-list {
      display: flex;
      padding-top: 5px;
      text-transform: capitalize;
      .icon-format {
        width: 27px;
        font-size: 1.125rem;
        display: block;
      }
    }
    a.doc-item-list {
      color: $PinkColor;
      text-decoration: none;
      &:hover {
        color: $blackColor;
      }
    }
    // a{
    //   color: $PinkColor;
    //   display: flex;
    //   align-items: center;
    //   text-decoration: none;
    //   &:hover{
    //     color: $blackColor;
    //   }
    //   //margin-left: 4px;
    //   .icon-folder-01{
    //     margin-right: 5px;
    //     .material-icons{
    //       font-size: 1.125rem;
    //       display: block;
    //     }
    //   }
    // }
  }
  .transfer-cont {
    display: flex;
    flex-flow: wrap;
    .column {
      flex: 0 0 50%;
    }
    .location-icon {
      height: 10px;
      width: 10px;
      border-radius: 5px;
      background-color: purple;
      float: left;
      margin-top: 4px;
      margin-right: 4px;
    }
  }

  .list-exhibit {
    margin-top: 5px;
  }

  .attachments-list {
    padding: 20px 20px 0;
    font-size: 0.875rem;
    .attachments-date {
      padding-left: 35px;
      opacity: 0.8;
    }

    a.attachments-list-link {
      color: $PinkColor;
      display: flex;
      align-items: center;
      padding-left: 35px;
      .icon-folder {
        margin-right: 5px;
        i {
          //margin-top: 5px;
          font-size: 1rem;
        }
      }
    }
    .attachments-status {
      position: relative;
      &::after {
        content: "";
        width: 8px;
        height: 8px;
        display: block;
        border-radius: 100%;
        position: absolute;
        top: 7px;
        left: 12px;
        background: linear-gradient(to right, rgb(29, 38, 113), rgb(195, 55, 100));
      }
      padding-left: 35px;
    }
  }
}

// Mapbox Gl CSS Start
.map-container {
  width: 100%;
  height: calc(100% - 69px);
}
.mapboxgl {
  position: relative;
  @include up(992px) {
    height: 100%;
  }
}
.mapboxglManagedWidth {
  width: calc(100% - 500px);
}
.drawerMapboxGLDiv {
  position: relative;
  height: 100%;
}
// Mapbox Gl CSS End

// Mapbox Leftside CSS Start
.overlay {
  position: absolute;
  top: 10px;
  left: 10px;
}

.overlay button {
  font: 600 12px/20px "DM Sans, sans-serif";
  background-color: #3386c0;
  color: #fff;
  display: inline-block;
  margin: 0;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 3px;
}

.overlay button:hover {
  background-color: #4ea0da;
}

.detail-page-wrapper {
  max-height: calc(100% - 69px);
  display: flex;
  position: relative;
  @include sm {
    flex-direction: column;
  }
}

.detail-page-wrapper .sidebar-wrapper {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  overflow-y: auto;
  z-index: 3;
  transition: 0.5s;
  height: 100%;
  @include sm {
    display: none;
  }
}

.show-sidebar-wrapper {
  max-width: 500px;
  flex: 0 0 500px;
}

.hide-sidebar-wrapper {
  max-width: 0px;
  flex: 0 0 0px;
}

.detail-page-wrapper .product-img-wrapper {
  max-height: 150px;
  height: 100%;
  background-position: center;
  background-size: cover;
  text-align: center;
  margin-bottom: 100px;
}

.product-detail-image-div {
  margin-bottom: 20px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

// .sidebar-wrapper .product-img-wrapper img.product-image {
//   padding-top: 60px;
// }

.product-details {
  padding: 20px 15px;
}
.sidebar-wrapper .product-details {
  padding-bottom: 0px;
}

.product-details .product-title {
  text-align: center;
  margin: 0;
  margin-bottom: 20px;
  font-size: 26px;
  font-weight: 600;
}

.product-impect {
  padding: 20px 0;
}

.product-impect .impect-heading {
  text-align: center;
  margin: 0;
  margin-bottom: 20px;
  font-size: 18px;
}

.tabs-list {
  border-radius: 10px;
  background: linear-gradient(to left bottom, #ff0, orange, #ff8c00);
  padding: 4px;
  margin-bottom: 10px;
}

.tabs-list > div {
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  font-size: 14px;
  letter-spacing: 1px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.tabs-list > div .tabs-text {
  max-width: calc(100% - 110px);
  flex: 0 0 calc(100% - 110px);
}

.tabs-list > div .tabs-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tabs-list > div .tabs-icons svg {
  margin: 0 8px;
  display: inline-flex;
  cursor: pointer;
}

.product-about table {
  border: 1px solid #333;
  border-radius: 5px;
}

.product-about table td {
  padding: 5px 10px;
}

.product-about table tr:nth-child(even) {
  background-color: #eeeeee;
}

.product-details .accordion-item {
  border: 0;
}

.product-details .accordion-item button.accordion-button.collapsed {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  background-color: transparent;
  font-weight: 400;
  color: #333;
}

.product-details .accordion-item:last-child button.accordion-button.collapsed {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  background-color: transparent;
}

.product-details .accordion-item button.accordion-button {
  background-color: rgba(223, 137, 1, 0.1);
  font-weight: 700;
  color: #333;
}

.product-details .accordion-item button.accordion-button:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.exhibitor-wrapper {
  text-align: center;
}

.exhibitor-wrapper .tags-wrap {
  display: inline-block;
  padding: 5px 20px;
  border: 2px solid #df8901;
  border-radius: 10px;
  color: #df8901;
  font-weight: 700;
  cursor: pointer;
  margin: 0 10px;
  font-size: 14px;
}

.product-img-wrapper img.product-image {
  height: 160px;
  width: 160px;
  object-fit: cover;
  padding: 5px;
  background: #fff;
  border-radius: 50%;
  top: 80px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 25%);
}

.product-img-wrapper img.product-image.product-detail-image {
  top: 50px;
  width: 300px;
  height: 300px;
}

.sidebar-options.open {
  left: 0;
  top: 0;
  transition: 0.5s;
}

.sidebar-options {
  position: absolute;
  width: 500px;
  height: calc(100% - 48px);
  background: #fff;
  top: 0;
  z-index: 4;
  left: -500px;
  transition: 0.5s;
  overflow: auto;
  @include up(992px) {
    height: 100%;
  }
}

.sidebar-wrapper > .close,
.sidebar-options > .close {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.sidebar-options .sidebar-img img {
  max-height: 200px;
  object-fit: cover;
}

@media (max-width: 1200px) {
  .detail-page-wrapper .sidebar-wrapper {
    max-width: 450px;
    flex: 0 0 450px;
  }
  .show-sidebar-wrapper {
    max-width: 450px;
    flex: 0 0 450px;
  }

  .detail-page-wrapper .mapboxgl {
    max-width: calc(100% - 450px);
    flex: 0 0 calc(100% - 450px);
  }

  .sidebar-options {
    width: 100%;
    max-width: 450px;
    /*width: 450px;*/
    left: -450px;
  }
}

@media (min-width: 992px) {
  .product-sticky-heading.show-mobile {
    display: none;
  }

  .sidebar-wrapper .close {
    display: none;
  }
}

@media (max-width: 991px) {
  .product-sticky-heading {
    position: static;
    top: 139;
    width: 100%;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    background-color: #323232;
    z-index: 1;
    color: #fff;
    padding: 5px 25px;
  }

  .product-sticky-heading .product-title {
    margin: 0;
    font-size: 22px;
    margin-right: 20px;
    font-weight: 500;
  }

  .product-sticky-heading a {
    color: #fff;
    text-decoration: none;
    opacity: 0.5;
  }

  .product-sticky-heading a:hover {
    opacity: 1;
    text-decoration: underline;
  }

  .show-sidebar-wrapper {
    max-width: 0px;
    flex: 0 0 0px;
  }

  .detail-page-wrapper .mapboxgl {
    max-width: 100%;
    flex: unset;
    height: calc(100% - 48px);
  }

  .mapboxglManagedWidth {
    width: 100%;
  }
}

// Mapbox Leftside CSS End

@keyframes movedots {
  from {
    transform: translate(-200px, -200px);
  }
  to {
    transform: translate(0, 0);
  }
}
.map_address {
  position: relative;

  &::before {
    content: "";
    width: 15px;
    height: 15px;
    background: linear-gradient(to right, rgb(29, 38, 113), rgb(195, 55, 100));
    display: block;
    border-radius: 100%;
    cursor: pointer;
    animation-name: movedots;
    animation-duration: 0.5s;
  }
  .tolltip-map-address {
    position: absolute;
    min-width: 200px;
    background: $WhiteColor;
    padding: 10px;
    font-size: 0.875rem;
    border: 1px solid rgba($color: $blackColor, $alpha: 0.2);
    border-radius: 5px;
    top: -20px;
    left: 20px;
    line-height: initial;
    font-weight: bold;
  }
}

.link-sub-attachments {
  color: $blackColor;
  padding: 5px 0 0 35px;
  &.ml0 {
    padding-left: 0;
  }
  .icon-attachments {
    background: $grayColor02;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 10px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 30px;
    font-weight: bold;
    font-size: 0.875rem;
    text-transform: uppercase;
    img {
      width: 25px;
      height: 25px;
    }
  }
}

.link-doc-title {
  color: $PinkColor;
}

.batch-container {
  background: $WhiteColor;
  .batch-content {
    max-width: 600px;
    margin: 0 auto;
    @include breakpoint(xs) {
      max-width: 90%;
    }
    .title-batch-content {
      padding: 50px 0;
      font-size: 1.25rem;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 1px;
      line-height: initial;
      text-align: center;
      color: $blackColor;
    }
    .batches-item {
      border-bottom: 1px solid rgba($color: $blackColor, $alpha: 0.1);
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
    .title-batch {
      line-height: initial;
      i {
        font: 0.875rem arial;
        display: block;
      }
    }
    .button-batch {
      background: #00861f;
      display: inline-block;
      padding: 3px 10px;
      color: #ffffff;
      font-size: 0.875rem;
      letter-spacing: 1px;
      border-radius: 5px;
      &:hover {
        text-decoration: none;
        background: #15a838;
      }
    }
  }
}
.content-blockchain {
  position: relative;
  overflow-y: hidden;
  min-height: $page-height;
  width: 100%;
  .w100 {
    width: 100%;
  }
  .img-blockchain-certified {
    @include breakpoint(xs) {
      display: none;
    }
    height: 200px;
  }
  .strip-status {
    padding-top: 20px;
    font-size: 1.25rem;
    font-weight: bold;
    img {
      margin-left: 20px;
    }
  }
  .section-btn-proof {
    margin-top: 20px;
    .btn {
      font-size: 0.875rem;
    }
  }
  .blockchain-left-content {
    height: 100%;
    display: flex;
  }
  .blockchain-certified {
    @include breakpoint(md) {
      max-width: 640px;
    }
    @include breakpoint(sm) {
      max-width: 640px;
    }
    @include breakpoint(xs) {
      border-radius: 0;
      border: 0;
    }

    width: 100%;
    margin: auto;
    border: 2px solid $primaryColor;
    padding: 15px;
    border-radius: 5px;
    background: #fff;
    z-index: 2;
    font-size: 0.875rem;
    p {
      margin: 0;
      word-break: break-all;
      &.title-bc {
        padding: 15px 0 0;
        font-size: 0.75rem;
        font-weight: bold;
        color: rgba($color: $blackColor, $alpha: 0.7);
        &.f {
          padding-top: 0;
        }
      }
    }
  }
  .chain {
    @include breakpoint(xs) {
      display: none;
    }
    position: absolute;
    right: 0;
    top: 0;
    margin: 0 20px;
    > div {
      position: relative;
      padding-top: 9px;
      &:after {
        width: 2px;
        height: calc(100% + 60px);
        content: "";
        position: absolute;
        top: -22px;
        //left: -50%;
        margin: calc(50% - 1px);
        background: #e3e3e3;
      }
      > div {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        &:not(.active) {
          background: #e3e3e3;
          height: 45px;
          width: 45px;
          margin-bottom: 20px;
          font-size: 0.75rem;
          .material-icons {
            font-size: 1.25rem;
            color: $blackColor;
            opacity: 0.7;
          }
        }
        &.active {
          background: $primaryColor;
          height: 64px;
          margin: -9px -20px 10px -20px;
          border-radius: 3px 0 0 3px;
          color: #ffffff;
          .material-icons {
            font-size: 2rem;
          }
          &::before {
            width: 100vh;
            height: 2px;
            content: "";
            background: #1e7e34;
            position: absolute;
            right: 85px;
          }
        }
      }
    }
  }
}

.blockchain-popup {
  background: rgba($color: $WhiteColor, $alpha: 0.9);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 3;
  .blockchain-popup-content {
    max-width: 600px;
    height: 80vh;
    overflow: auto;
    margin: 5vh auto;
    background: #fff;
    border: 2px solid rgba($color: $blackColor, $alpha: 0.4);
    padding: 10px;
    font-size: 0.875rem;
  }
}

.popup-blockchain {
  .popup-bg {
    background-color: rgba($color: $WhiteColor, $alpha: 0.8);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
  }
  .popup-modal {
    border-radius: 5px;
    background: #fff;
    width: 100vh;
    @include breakpoint(xs) {
      width: initial;
    }
    padding: 15px 0;
    z-index: 1;
    box-shadow: #cccccc 0px 0px 40px;
  }
}
.blockchain-popup-content {
  padding: 0 10px;
  margin: 0 10px 10px;
  max-height: 60vh;
  overflow-y: auto;
  table {
    width: 100%;
    font-size: 0.8125rem;
    td {
      word-break: break-word;
    }
  }
}

.content-document {
  width: 100%;
  min-height: $page-height;
  background-color: #ffffff;
  .document-frame {
    width: 100%;
    height: $page-height;
    min-height: $page-height;
    border: 0;
    display: block;
  }
}

._pt5 {
  padding-top: 5px;
}
._p10 {
  padding: 10px;
}

// Mapbox Tooltip CSS
.mapboxgl-popup-content {
  padding: 0px;
  max-height: 250px;
  overflow: auto;
}
.tooltipRootDiv {
  width: 100%;
  padding: 10px;
  & > p {
    margin: 0px;
    font-size: 12px;
    &:last-child {
      font-size: 10px;
      color: #999;
    }
  }
}
.tooltipUl {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
}
.tooltipLi {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 15px;
  box-sizing: border-box;
  cursor: pointer;
  & > span {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 15px;
  }
  & > div {
    margin: 0px;
    width: calc(100% - 35px);
    & > p {
      margin-bottom: 0px;
      &:nth-child(1) {
        font-size: 12px;
        text-transform: uppercase;
      }
      &:nth-child(2) {
        font-size: 10px;
      }
      &:last-child {
        font-size: 8px;
        color: #999;
      }
    }
  }
  &:hover {
    background-color: #f3f3f3;
  }
}

// Flex classes
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

// Fancybox CSS
.fancybox-button--zoom {
  display: none !important;
}

.fancybox-is-open .fancybox-bg {
  opacity: 0.5 !important;
}

// Blockchain Proof Model CSS
.blockchainProofModel {
  & > div {
    max-width: max-content;
    min-width: 50%;
    & > div {
      & > div:nth-child(2) {
        & > div {
          min-height: max-content !important;
        }
      }
    }
  }
}

.fullScreenBlockChainProofModel {
  & > div {
    max-width: 90%;
    height: 100%;
    & > div {
      height: 100%;
      & > div:nth-child(2) {
        & > div {
          min-height: 100% !important;
        }
      }
    }
  }
}

// Mobile Product Detail CSS
.mobile-product-detail-root {
  padding-bottom: 20px;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  .pd-top {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .product-image {
    width: 100%;
    margin-top: 20px;
    & > img {
      width: 100%;
      height: auto;
      max-height: 100%;
      max-width: 50%;
    }
  }
  .product-title {
    padding: 19px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
    & > h2 {
      color: $primaryColor;
      letter-spacing: 0.1em;
      font-size: 30px;
      font-weight: 600;
      line-height: 36px;
      margin-right: 10px;
      margin-bottom: 0px;
      max-width: calc(100% - 40px);
    }
    & > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .blockchain-proof-icon {
        margin-left: 10px;
        width: 22px;
        height: 22px;
        background-color: #008f2b29;
        border-radius: 50%;
        cursor: pointer;
        & > svg {
          width: 14px;
          height: 16px;
          fill: $primaryColor;
        }
      }
    }
  }
  .i-button {
    border-radius: 60px;
    color: $primaryColor;
    background-color: #008f2b29;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    & > img {
      width: 10px;
      height: 10px;
    }
    .i-button-close {
      filter: invert(0.5);
    }
  }
  .i-button-icon {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
  .product-detail-tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 23px;
    width: 100%;
    overflow: auto;
    .product-detail-tab {
      color: $grayColor76;
      font-size: 16px;
      line-height: 14px;
      font-weight: 700;
      padding-bottom: 10px;
      margin: 0;
      margin-right: 20px;
      text-transform: uppercase;
      &:last-child {
        margin-right: 0px;
      }
      @include down(400px) {
        font-size: 13px;
      }
    }
    .active {
      color: $primaryColor;
      border-bottom: 3px solid $primaryColor;
    }
  }
  .ingredients {
    margin-bottom: 23px;
  }
  .information {
    margin-bottom: 23px;
    .information-li {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 15px;
      & > p {
        margin: 0;
        font-size: 15px;
        line-height: 18px;
        padding-right: 5px;
        box-sizing: border-box;
        font-weight: 400;
        color: $grayColor76;
        & > span {
          font-weight: 700;
          color: $primaryColor;
          margin-right: 10px;
        }
      }
    }
  }
  .documents {
    margin-bottom: 23px;
    .documents-li {
      margin: 10px 0px;
      color: $primaryColor;
      .documents-name {
        color: $primaryColor;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        margin: 0;
      }
      .documents-buttons {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        .documents-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $grayColor76;
          font-weight: 500;
          font-size: 13px;
          line-height: 15px;
          border: 1.2px solid $primaryColor;
          border-radius: 6px;
          filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.25));
          padding: 10px 12px;
          background: $WhiteColor;
          margin-right: 10px;
          margin-top: 10px;
          & > img {
            width: 12px;
            height: 12px;
            margin-right: 10px;
          }
        }
      }
      .documents-video-div {
        width: 100%;
        max-height: 200px;
        margin: 15px auto;
        & > video {
          width: 100%;
          max-height: 200px;
        }
      }
    }
  }
  .product-details-div {
    margin-bottom: 23px;
    .product-details-heading {
      margin: 20px 0 10px;
      color: $primaryColor;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
    }
    .product-details-text {
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
    }
  }
  .buynow-button {
    & > button {
      background-color: $primaryColor;
      width: 100%;
      text-transform: uppercase;
      color: $WhiteColor;
      border: none;
      border-radius: 5px;
      font-size: 25px;
      line-height: 30px;
      font-weight: 600;
      padding: 9px 0px;
      cursor: pointer;
    }
  }
  .buynow-disabled-button {
    pointer-events: none;
    background-color: $disabledColor !important;
  }
  .zero-state-message {
    text-align: center;
  }
  .pdlr-padding {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.sm-product-leftside-show .mobile-product-detail-root {
  min-height: 100%;
}

.loading-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & > p {
    padding: 30px 0;
    text-align: center;
  }
}

.ellipsis {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

// Overwrite Fancybox CSS
.fancybox-content {
  @include sm {
    height: calc(100% - 88px) !important;
  }
}

// Overwrite Google Translate CSS
.goog-text-highlight {
  background-color: unset !important;
  box-shadow: unset !important;
  -webkit-box-shadow: unset !important;
}

// Product Ingredients CSS
.ingredients-content {
  border: 1px solid $primaryColor;
  border-radius: 5px;
  .ingredient-heading {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 16px;
    min-height: 45px;
    border-bottom: 1px solid $primaryColor;
    & > h5 {
      margin: 0;
      color: $primaryColor;
      font-size: 15px;
      line-height: 18px;
      font-weight: 400;
      text-transform: capitalize;
    }
  }
  .ingredient-root-ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .ingredients-li {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid $grayColorD9;
    display: flex;
    align-items: center;
  }
  .ingredients-li-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    min-height: 45px;
    width: 100%;
    box-sizing: border-box;
    & > img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    & > div {
      width: calc(100% - 50px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > h5 {
        margin: 0;
        font-size: 15px;
        line-height: 18px;
        font-weight: 400;
        text-transform: capitalize;
        color: $grayColor76;
        width: calc(100% - 18px);
      }
      & > img {
        width: 15px;
        height: 15px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
    @include sm {
      padding: 6px 8px;
      & > img {
        width: 30px;
        height: 30px;
      }
      & > div {
        width: calc(100% - 40px);
        & > h5 {
          font-size: 14px;
        }
      }
    }
  }
  // .ingredient-child-ul {
  //   margin: 0;
  //   padding: 0;
  //   list-style-type: none;
  //   .ingredients-li {
  //     padding-left: 30px;
  //     &::before {
  //       content: "";
  //       width: 5px;
  //       height: 5px;
  //       background-color: $grayColor76;
  //       border-radius: 50%;
  //       display: inline-block;
  //     }
  //   }
  // }
}

.desktop-ingredients {
  border: 1px solid $grayColorD9;
  .ingredient-heading {
    border-bottom: 1px solid $grayColorD9;
    & > h5 {
      color: $blackColor;
      font-weight: 600;
    }
  }
}

.zero-state-product-ingredients {
  width: 100%;
  margin: 0;
  padding: 30px 0;
  text-align: center;
}
// Product journey CSS
.pj-root {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 30px 0px;
  & > div {
    width: 100%;
  }
  .pj-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 20px);
    margin: 0px 10px 30px;
    &::after {
      content: "";
      display: block;
      width: 0;
      height: 100%;
      position: absolute;
      top: 0;
      left: 54px;
      border-left: 3px solid $grayColor6E;
      z-index: -1;
    }
    & > div {
      &:nth-child(1) {
        border: 3px solid $grayColor6E;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin: 0px 5px;
        & > svg {
          width: 20px;
          height: 20px;
          color: $grayColor6E;
        }
      }
      &:nth-child(2) {
        border-radius: 50%;
        border: 2px solid $grayColor6E;
        background-color: $primaryColor;
        width: 10px;
        height: 10px;
        margin-right: 10px;
        box-sizing: border-box;
      }
      &:nth-child(3) {
        width: calc(100% - 60px);
        height: max-content;
        background-color: $primaryColor;
        position: relative;
        box-shadow: 0 1px 8px 0 #0000000f;
        background-color: $WhiteColor;
        padding: 15px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        &::before {
          content: "";
          display: block;
          width: 0;
          height: 0;
          position: absolute;
          top: calc(50% - 8px);
          left: 0;
          right: 100%;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-right: 10px solid $WhiteColor;
          margin-left: -10px;
        }
        .pj-date {
          font-size: 14px;
          color: $grayColor99;
          margin-bottom: 10px;
        }
        .pj-product-section {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 10px;
          & > img {
            width: 70px;
            height: 70px;
            box-shadow: 0 1px 8px 0 #0000000f;
            border-radius: 5px;
            margin-right: 10px;
          }
          .pj-attachment-image {
            transition: 0.5s;
            -webkit-transition: 0.5s;
            -moz-transition: 0.5s;
            &:hover {
              box-shadow: 0 8px 15px rgba(0, 0, 0, 0.35);
              transform: scale(1.2);
            }
          }
          .pj-circle-image {
            border-radius: 50%;
            width: 50px;
            height: 50px;
          }
          & > div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
          }
        }
        .pj-label {
          font-weight: 600;
          font-size: 16px;
          color: $grayColorD9;
          margin: 0;
          padding: 0;
        }
        .pj-attachment-heading {
          font-weight: 600;
          font-size: 16px;
          color: $grayColorD9;
          margin: 0;
          padding: 0;
          &:hover {
            color: $primaryColor;
          }
        }
        .pj-product-description {
          font-size: 14px;
          text-align: justify;
          margin-bottom: 10px;
        }
        .pj-location {
          font-size: 14px;
          margin: 0px;
        }
      }
    }
    .pj-attachment-icon {
      transition: 0.5s;
      -webkit-transition: 0.5s;
      -moz-transition: 0.5s;
      &:hover {
        transform: scale(1.2);
        box-shadow: 0 8px 15px rgba(0, 0, 0, 0.35);
      }
    }
  }
}

.privacy-policy-link {
  padding: 0px 25px;
  & > p {
    font-size: 12px;
    font-weight: $medium500;
    font-family: $fontFamily;
    & > a {
      margin: 0px 5px;
      text-decoration: underline;
      color: $primaryColor;
    }
  }
}

@include sm {
  .mlpj-hide-sm {
    display: none;
  }
}

@media (max-width: 991px) {
  .blockchainProofModel {
    & > div {
      max-width: 100%;
      & > div {
        & > div:nth-child(2) {
          & > div {
            min-height: max-content !important;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .mobile-product-detail-root .product-title > h2 {
    font-size: 20px;
  }
  .mobile-product-detail-root .product-image > img {
    max-width: 70%;
  }
}

@media (max-width: 400px) {
  .product-sticky-heading {
    .product-title {
      font-size: 16px;
    }
  }
  .mobile-product-detail-root .product-image > img {
    max-width: 100%;
  }
}

// Mockup5 CSS
$mockup5ImageWidth: 380px;
$mockup5leftRightQuestionBoxPx: 320px;
$mockup5QuestionBoxSize: 200px;
$mockup5lineWidth: 130px;
.product-detail-mockup5-points-border {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: $mockup5ImageWidth - 100px;
  height: $mockup5ImageWidth - 100px;
  border: 2px solid #28a745;
  border-radius: 50%;
  z-index: -2;
}

.product-detail-mockup5-points {
  width: 12px;
  height: 12px;
  background-color: $primaryColor;
  border-radius: 50%;
  border: 2px solid $WhiteColor;
  display: block;
  position: absolute;
  top: ($mockup5ImageWidth / 2) - 6px;
  left: ($mockup5ImageWidth / 2) - 6px;
  z-index: -1;
  &::before {
    position: absolute;
    content: "";
    background-color: $primaryColor;
    height: 2px;
    top: 50%;
    left: 3px;
    transform-origin: left;
    width: 0px;
  }
  &.point-width {
    &::before {
      width: $mockup5lineWidth;
    }
  }
}

@for $i from 1 to 25 {
  .point-#{$i} {
    transform: rotate(-#{$i * 15}deg) translate(($mockup5ImageWidth - 100px) /2) rotate(#{$i * 15}deg);
  }
}

$mockup5PointsData: 1 340deg, 2 325deg, 10 -144deg, 11 -159deg, 12 -180deg, 13 -202deg, 14 -216deg, 22 395deg, 23 382deg,
  24 0deg;
@each $i, $rotate in $mockup5PointsData {
  .point-#{$i}::before {
    animation: mockup5PointLinkAnimation 1s 1s ease-in-out;
    transform: translateY(-50%) rotate($rotate);
  }
}

@keyframes mockup5PointLinkAnimation {
  0% {
    width: 0px;
  }
  100% {
    width: $mockup5lineWidth;
  }
}

.product-detail-questions-mockup5 {
  width: 100%;
  max-width: $mockup5QuestionBoxSize;
  position: absolute;
  left: ($mockup5ImageWidth - $mockup5QuestionBoxSize) / 2;
  top: ($mockup5ImageWidth / 2) - 20px;
  display: none;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px #00000040;
  height: 40px;
  border: 2px solid $primaryColor;
  background-color: #c9e9d0;
  & > p,
  & > a > p {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #000;
    text-transform: capitalize;
    width: 100%;
    & > svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    & > span {
      width: calc(100% - 30px);
    }
  }
  & > a {
    width: 100%;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}

$mockup5ZoomInKeyFrameData: 1 -140px, 2 -70px, 3 0px, 4 70px, 5 140px, 6 140px, 7 70px, 8 0px, 9 -70px, 10 -140px;
@each $i, $translateY in $mockup5ZoomInKeyFrameData {
  .pd-question-#{$i} {
    transform: translate(#{if($i < 6, -$mockup5leftRightQuestionBoxPx, $mockup5leftRightQuestionBoxPx)}, $translateY);
  }
}
@each $i, $translateY in $mockup5ZoomInKeyFrameData {
  @keyframes zoomInMockup5Question#{$i} {
    0% {
      left: 50%;
      transform: translate(-50%) scale(0);
    }
    100% {
      left: ($mockup5ImageWidth - $mockup5QuestionBoxSize) / 2;
      transform: translate(#{if($i < 6, -$mockup5leftRightQuestionBoxPx, $mockup5leftRightQuestionBoxPx)}, $translateY)
        scale(1);
    }
  }
}

// Product detail mobile CSS
.mobile-product-image {
  .mobile-product-image-wrapper {
    max-width: 360px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    & > img {
      z-index: 1;
      width: 100%;
      height: 100%;
      position: relative;
    }
    &::before {
      position: absolute;
      content: "";
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background-color: $primaryColor;
      pointer-events: none;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    @include xs {
      max-width: 250px;
    }
  }
}

.mobile-product-detail-buttons {
  position: relative;
  &::before {
    position: absolute;
    content: "";
    height: calc(100% - 15px);
    width: 2px;
    top: 0;
    background-color: $primaryColor;
    left: 50%;
    transform: translateX(-50%);
  }
  & > div {
    margin: 0 auto 30px;
    display: flex;
    flex-wrap: wrap;
    max-width: 480px;
    padding: 50px 0 0;
    .mobile-product-detail-questions {
      width: 100%;
      cursor: pointer;
      max-width: calc(50% - 30px);
      flex: 0 0 calc(50% - 30px);
      margin-left: 0;
      margin-right: auto;
      border-radius: 4px;
      border: 1px solid $primaryColor;
      background-color: #c9e9d0;
      margin-bottom: 25px;
      min-height: 35px;
      line-height: 35px;
      font-weight: 700;
      position: relative;
      z-index: 1;
      opacity: 0;
      animation: mobileQuestionBoxOpacityAnimation 2s ease-in-out;
      & > p,
      & > a > p {
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #000;
        text-transform: capitalize;
        width: 100%;
        & > svg {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
        & > span {
          width: calc(100% - 30px);
        }
      }
      & > a {
        width: 100%;
        text-decoration: none;
        &:hover {
          text-decoration: none;
        }
      }
      &::before {
        position: absolute;
        content: "";
        width: 30px;
        height: 2px;
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        background-color: $primaryColor;
        z-index: -1;
        animation: questionBeforeAnimation 2s ease-in-out;
      }
      &::after {
        position: absolute;
        content: "";
        width: 12px;
        height: 12px;
        background-color: $primaryColor;
        border-radius: 50%;
        outline: 2px solid $WhiteColor;
        top: 50%;
        left: calc(100% + 30px);
        transform: translateY(-50%) translateX(-5px);
        opacity: 1 !important;
      }
      &:nth-child(even) {
        margin-left: auto;
        margin-right: 0;
        transform: translateY(30px);
        &::before {
          left: auto;
          right: 100%;
        }
        &::after {
          left: auto;
          right: calc(100% + 30px);
          transform: translateY(-50%) translateX(5px);
        }
      }
      &.pd-after-animation-style {
        opacity: 1;
      }
    }
    @include xs {
      padding: 30px 0 0;
    }
  }
}

@keyframes mobileQuestionBoxOpacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes questionBeforeAnimation {
  0% {
    width: 0px;
  }
  100% {
    width: 30px;
  }
}
