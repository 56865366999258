$primaryTextColor: #444444;
$blackColor: #000000;
$blackColor33: #333;
$blackColor66: #666;
$blackColor01: #444444;
$blackColor02: #131313;
$WhiteColor: #ffffff;
$WhiteColor01: #f6f9fc;
$grayColor01: #efefef;
$grayColor02: #cccccc;
$grayColor03: #f9f9f9;
$grayColorD9: #d9d9d9;
$grayColor09: #f7f7f7;
$grayColor76: #767676;
$grayColor6E: #6e6e6e;
$grayColor6C: #6c6c6c;
$grayColor99: #999999;
$grayColorE8: #e8e8e8;
$grayColorB0: #b0b0b0;
$grayColorC4: #c4c4c4;
$grayColorDE: #dedede;
$grayColorF8F9FB: #f8f9fb;
$blueColor01: #e8f2e7;
$PinkColor: #a43e97;
$AquaColor: #63bff6;
$borderColor: rgba(68, 68, 68, 0.5);
$errorColor: #d6513f;
$facebookColor: #1877f2;
$googleColor: #4285f4;
$primaryColor: #28a745;
$secondaryColor: #eaf7ed;
$disabledColor: #eee;
$lightPrimaryColor : #53b86a;
// Font Family
$fontFamily: DM Sans, sans-serif;
// Font Weights
$lighter300: 300;
$light400: 400;
$medium500: 500;
$bold600: 600;
$bolder700: 700;
