@import "./../../styles/app-constant.scss";
@import "./../../styles/app-variables.scss";
@import "./../../styles/mixin.scss";

.authlinkbox {
  background-color: $WhiteColor;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 7px 14px 0px, rgba(0, 0, 0, 0.07%) 0px 3px 6px 0px;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
  & > h4 {
    color: $blackColor;
    font-weight: $bold600;
    & > span {
      color: $primaryColor;
    }
  }
  & > p {
    font-size: 16px;
    line-height: 19px;
    color: $blackColor66;
    & > a {
      color: $primaryColor;
    }
  }
  & > a {
    text-decoration: none;
    & > button {
      border-radius: 5px;
      padding: 10px 15px;
      background-color: $lightPrimaryColor;
      color: $WhiteColor;
      & > span > span > svg {
        transform: rotateY(180deg);
      }
      &:hover {
        background-color: $blackColor;
      }
    }
  }
}
