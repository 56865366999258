@import "./../../styles/app-constant.scss";
@import "./../../styles/app-variables.scss";
@import "./../../styles/mixin.scss";

// CircularProgress Component CSS
.loaderRoot {
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

// loader animation
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Label Component CSS
.labelRoot {
  margin-bottom: 5px;
  display: block;
}

.label_error {
  color: $errorColor;
}

.requiredLabelSpan {
  color: $errorColor;
}

// Integrant Blockchain Proof Component CSS
.integrant-blockchain-proof {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .content-blockchain {
    position: relative;
    overflow-y: hidden;
    min-height: max-content !important;
    width: 100%;
    .w100 {
      width: 100%;
    }
    .img-blockchain-certified {
      height: 200px;
    }
    .strip-status {
      padding-top: 20px;
      font-size: 1.25rem;
      font-weight: bold;
      img {
        margin-left: 20px;
      }
    }
    .section-btn-proof {
      margin-top: 20px;
      .blockchain-proof-toggler {
        font-size: 0.875rem;
        border-radius: 5px;
        background-color: $primaryColor;
        cursor: pointer;
        padding: 8px 10px;
        color: $WhiteColor;
        width: max-content;
        height: max-content;
      }
    }
    .blockchain-left-content {
      height: 100%;
      display: flex;
    }
    .blockchain-certified {
      @include breakpoint(md) {
        max-width: 640px;
      }
      @include breakpoint(sm) {
        max-width: 640px;
      }
      @include breakpoint(xs) {
        border-radius: 0;
        border: 0;
        & > div {
          flex-direction: column-reverse;
        }
      }
      width: 100%;
      margin: auto;
      border: 2px solid $primaryColor;
      padding: 15px;
      border-radius: 5px;
      background: #fff;
      z-index: 2;
      font-size: 0.875rem;
      p {
        margin: 0;
        word-break: break-all;
        &.title-bc {
          padding: 15px 0 0;
          font-size: 0.75rem;
          font-weight: bold;
          color: rgba($color: $blackColor, $alpha: 0.7);
          &.f {
            padding-top: 0;
          }
        }
      }
    }
    .blockchain-certified-documents {
      text-align: left;
    }
  }
  .popup-blockchain {
    .popup-bg {
      background-color: rgba($color: $WhiteColor, $alpha: 0.2);
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 3;
    }
    .popup-modal {
      border-radius: 5px;
      background: #fff;
      width: 100vh;
      @include breakpoint(xs) {
        width: initial;
      }
      padding: 15px 0;
      z-index: 1;
      box-shadow: #cccccc 0px 0px 40px;
    }
  }
  .blockchain-popup-content {
    padding: 0 10px;
    margin: 0 10px 10px;
    max-height: 60vh;
    overflow-y: auto;
    table {
      width: 100%;
      font-size: 0.8125rem;
      td {
        word-break: break-word;
      }
    }
  }
}

// Loading Text With Circular Progress Component CSS
.loading-text-with-circularprogress {
  width: 100%;
  & > p {
    margin: 0;
  }
  .circular-progress-div {
    margin-left: 10px;
  }
}

// Overwrite Bootstrap Model CSS
.model-close-button {
  width: max-content;
  height: max-content;
  padding: 5px 10px;
  vertical-align: unset;
}

@media (max-width: 768px) {
  .integrant-blockchain-proof {
    .title-bc > div {
      flex-direction: column;
    }
    .img-blockchain-certified {
      display: block !important;
    }
  }
}
// Slider CSS
.slider-root {
  .slick-prev,
  .slick-next {
    width: unset;
    height: unset;
    &::before {
      content: unset;
    }
  }
  .slick-track {
    display: flex;
    align-items: center;
    .slick-slide {
      min-width: 100px;
      width: auto !important;
      margin-right: 15px;
    }
  }
  & > button {
    border: 1px solid $primaryColor;
    border-radius: 50%;
    padding: 2px;
    & > span {
      & > svg {
        color: $primaryColor;
      }
    }
    &:nth-child(1) {
      left: 0;
    }
    &:nth-child(3) {
      right: 0;
    }
    &:hover {
      background-color: $primaryColor;
      & > span {
        & > svg {
          color: $WhiteColor;
        }
      }
    }
  }
  .disabled-icon {
    pointer-events: none;
    border: 1px solid $disabledColor;
    & > span {
      & > svg {
        color: $disabledColor;
      }
    }
  }
  & > div {
    margin: 0px auto;
    width: calc(100% - 80px);
  }
  @include sm {
    & > button {
      & > span {
        & > svg {
          width: 15px;
          height: 15px;
        }
      }
    }
    & > div {
      width: calc(100% - 62px);
    }
  }
}
