@import "./../../styles/app-constant.scss";
@import "./../../styles/app-variables.scss";
@import "./../../styles/mixin.scss";

.header-blog-news {
  background: $disabledColor;
  padding: 2% 0;
  @include breakpoint(xs) {
    padding: 20px 0;
  }
  p {
    margin: 0;
  }
  .hl-title {
    font: bold 2rem arial;
    color: $primaryColor;
    @include breakpoint(xs) {
      font-size: 1.25rem;
    }
  }
  .hl-des {
    font-style: italic;
    @include breakpoint(xs) {
      font-size: 0.875rem;
    }
  }
}

.section-blog-news {
  .menu-category {
    list-style: none;
    padding: 20px 0;
    margin: 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $borderColor;
    li {
      &:first-child {
        margin-right: 20px;
        font-size: 0.875rem;
        font-weight: bold;
        @include breakpoint(xs) {
          font-size: 0.75rem;
          margin-right: 10px;
        }
      }
      &:not(:first-child) {
        margin-right: 10px;
      }
      a {
        color: $blackColor;
        padding: 7px 20px;
        border-radius: 20px;
        @include breakpoint(xs) {
          font-size: 0.875rem;
        }
        &.active {
          background: $primaryColor;
          color: #fff;
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .list-news {
    padding: 20px 0;
    border-bottom: 1px solid rgba(104, 88, 88, 0.1);
    p {
      margin: 0;
    }
    img {
      border-radius: 10px;
    }
    .btn {
      border-radius: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      @include breakpoint(sm) {
        display: flex;
      }
      flex-wrap: wrap;
      align-items: center;
      font-size: 0.875rem;
      li {
        display: flex;
        align-items: center;

        strong {
          padding-left: 5px;
        }
        a {
          color: $blackColor;
          font-weight: bold;
        }
      }
      &.ibn-list {
        padding-top: 5px;

        li {
          &:not(:first-child) {
            &::before {
              content: "";
              margin: 0 15px;
              width: 5px;
              height: 5px;
              border-radius: 100%;
              background: $blackColor01;
              display: flex;
            }
          }
        }
      }
      &.ibn-footer {
        padding-top: 15px;
        li {
          background: $disabledColor;
          padding: 5px 20px;
          border-radius: 20px;
          &:not(:first-child) {
            margin-left: 10px;
          }
          @include breakpoint(xs) {
            display: inline-block;
          }
        }
      }
    }
    .ibn-title {
      line-height: initial;
      @include breakpoint(xs) {
        padding-top: 20px;
      }
      a {
        color: $blackColor;
        font-weight: bold;
        font-size: 1.125rem;
      }
    }
    .ibn-subtitle {
      padding: 15px 0;
    }
  }
}

.section-blog-detail {
  max-width: 800px;
  margin: 0 auto;
  @include breakpoint(xs) {
    max-width: 90%;
    padding-top: 20px;
  }
  .sbd-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: $primaryColor;
  }
  ul {
    &.ibn-list {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      li {
        display: flex;
        align-items: center;
        strong {
          padding-left: 5px;
        }
        &:not(:first-child) {
          &::before {
            content: "";
            margin: 0 15px;
            width: 5px;
            height: 5px;
            border-radius: 100%;
            background: $blackColor01;
            display: flex;
          }
        }
      }
    }
  }
  .sbd-img {
    margin: 15px 0;
  }
  .sbd-subtitle {
    padding-bottom: 15px;
  }
  h2 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-top: 30px;
    color: $primaryColor;
  }
}

.breadcrumb-list {
  font-size: 12px;
  list-style: none;
  padding: 20px 0;
  margin: 0;
  display: flex;
  @include breakpoint(xs) {
    display: none;
  }
  li {
    text-transform: capitalize;
    color: $blackColor01;
    a {
      color: $primaryColor;
    }
    &:not(:last-child) {
      &:after {
        content: "/";
        padding: 0 8px;
      }
    }
  }
}

.page-blog-news,
.section-blog-detail {
  flex: 1;
}
