/**Import bootstrap**/
@import "~bootstrap/scss/bootstrap";
@import "~rc-pagination/assets/index.css";
@import url("//fonts.googleapis.com/icon?family=Material+Icons");
@import "./global.scss";


.feedHeaderRoot{
	margin-bottom: 20px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}
.feedMainRoot{
border: 1px solid #e5e5e5;
padding: 15px;	
margin-bottom: 20px;
}
.addFeedHeaderRoot p{
	opacity: 0.5
}
.commentReplyRootDiv .ffcarRightSideDiv{
	max-width: calc(100% - 60px);
	width: 100%;
	margin-left: auto;
	@include xs{
		max-width: calc(100% - 45px);	
	}
}
.commentReplyRootDiv .ffcarRightSideDiv .ffcarContentDiv{
	width: 100%;
	background-color: #f4f5f8;
	padding: 10px 15px;
	border-radius: 4px;
	margin-bottom: 10px;
}
.commentReplyRootDiv > div .messageCommentContent{
	font-size: 16px;
	font-weight: 400;
	line-height: 1.4;
	white-space: pre-line;
	word-break: break-word;
	width: 100%;
}
.commentReplyRootDiv > div .messageContent{
	font-weight: 600;
	font-size: 14px;
	line-height: 1.4;
	@include xs{
		margin-bottom: 0;
	}
}