@import "./../../styles/app-variables.scss";
@import "./../../styles/mixin.scss";
.welcome-wrapper {
  .popup-bg {
    z-index: 999;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    .popup-modal {
      background: #fff;
      min-width: 400px;
      z-index: 1;
      header {
        background: #0c3258;
        color: #fff;
        padding: 10px;
        display: flex;
        .close-btn {
          button {
            border: none;
            color: #fff;
            background: none;
          }
        }
      }

      .content {
        min-height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.welcome-wrapper ~ .page-products-content .product-main-description {
  filter: blur(4px);
  transition: all 0.3s ease-in 0s;
}

.page-products-content {
  width: 100%;
  height: 100%;
}
.page-products {
  height: calc((var(--vh, 1vh) * 100) - 100px);
}
