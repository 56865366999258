@import "./../../styles/mixin.scss";
@import "./../../styles/app-variables.scss";
@import "./../../styles/app-constant.scss";

.btn-success {
  background: $primaryColor;
  border: 0;
}

.bg-homepage {
  background-color: $blueColor01;
  padding-bottom: 20px;
  @include breakpoint(xs) {
    background-image: inherit !important;
  }
  @include breakpoint(sm) {
    background-size: cover;
    background-attachment: fixed;
  }
  .main-header {
    box-shadow: none;
  }
  .home-banner {
    @include breakpoint(md) {
      min-height: $page-height;
      align-items: center;
      display: flex;
    }
    > .container-lg {
      padding: 0;
    }
    .featured-images {
      display: flex;
      overflow: hidden;
      @include breakpoint(xs) {
        overflow-x: auto;
      }
      .featured-phone {
        padding: 15px;
        background: $WhiteColor01;
        border-radius: 36px;
        margin-right: 10px;
        box-shadow: inset 0 -2px 6px 0 #2b303559;
        width: 100%;
        min-width: 200px;
        img {
          width: 100%;
          border-radius: 20px;
        }
        @include breakpoint(xs) {
          margin: 0 10px 15px;
          &:first-child {
            margin-left: 15px;
          }
        }
        @include breakpoint(md) {
          min-width: 39vh;
        }
        @include breakpoint(sm) {
          margin-right: 10px;
          min-width: initial;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  .box-home-banner {
    @include breakpoint(sm) {
      padding: 30px 0;
    }
    @include breakpoint(md) {
      padding: 0 0 0 90px;
    }

    .box-btn {
      margin: 20px 0;
      .btn {
        font-size: 24px;
        padding: 15px 20px;
        width: 250px;
        line-height: 1;
        &.btn-success {
          border: 0;
        }
      }
      @include sm {
        text-align: center;
        .btn {
          font-size: 20px;
          width: 200px;
        }
      }
    }
    .box-sub-text {
      font-size: 1.125rem;
      text-align: justify;
      @include sm {
        margin-bottom: 20px;
      }
    }
  }
}

.page-homepage {
  .title {
    line-height: initial;
    font-weight: bold;
    @include breakpoint(xs) {
      text-align: center;
      font-size: 1.5rem;
      padding: 15px 0;
      .text-healthLoqc {
        display: inline-block;
      }
    }
    @include breakpoint(sm) {
      font-size: 2rem;
    }
    @include breakpoint(lg) {
      font-size: 3rem;
    }
    span {
      color: $primaryColor;
    }
  }

  .featured-Companies-logos {
    @include breakpoint(xs) {
      padding: 20px 0;
    }
    @include breakpoint(sm) {
      padding: 60px 0;
    }
  }

  .text-fortunate {
    @include breakpoint(sm) {
      padding: 10px 0 80px;
    }
    @include breakpoint(md) {
      font-size: 1.125rem;
      padding: 20px 0 80px;
    }
  }

  p {
    margin-bottom: 0;
  }
  .section-about-healthLoq {
    @include breakpoint(md) {
      padding-bottom: 15vh;
      //padding-bottom: 20vh;
    }

    .box-featured-content {
      text-align: center;
      box-shadow: $grayColor02 0px 20px 28px -23px;
      @include breakpoint(xs) {
        display: flex;
        align-items: center;
        margin-bottom: 50px;
        font-size: 0.75rem;
        text-align: left;
        > div {
          padding-left: 20px;
        }
      }
      @include breakpoint(sm) {
        display: flex;
        align-items: center;
        margin-bottom: 50px;
      }
      @include breakpoint(md) {
        display: block;
        margin-bottom: 0;
      }
      @include breakpoint(lg) {
        display: block;
        margin-bottom: 0;
      }
      .box-featured-title {
        color: $primaryColor;
        font-size: 1.5rem;
        padding: 30px 0 10px;
      }
      .box-featured-text {
        padding: 0 7%;
        @include breakpoint(xs) {
          padding: 0 0 30px 0;
        }
        @include breakpoint(sm) {
          font-size: 1.125rem;
          min-height: 150px;
        }
        @include breakpoint(md) {
          min-height: 150px;
        }
      }
      .img-featured {
        @include breakpoint(lg) {
          max-width: 160px;
        }
        @include breakpoint(sm) {
          height: 150px;
        }
        @include breakpoint(xs) {
          height: 150px;
        }
      }
    }
  }
  .section-as-seen-in {
    padding: 0px 0 80px;
    .seen-in-img-main {
      display: flex;
    }
    .certified-partners {
      margin: 40px 0 40px 0;
    }
  }
}

.box-home-banner {
  // .title{
  //     @include breakpoint(md){
  //         font-size: 3rem;
  //         // span{
  //         //     display: block;
  //         // }
  //     }
  // }
}

.container-certified-partners {
  @include breakpoint(sm) {
    padding-top: 60px;
  }
  @include breakpoint(xs) {
    padding-top: 20px;
  }
  .text-transparent-companies {
    display: block;
    color: $blackColor01 !important;
    padding-top: 5px;
    font-style: italic;
    @include breakpoint(sm) {
      font-size: 1.125rem;
      font-weight: normal;
    }
    @include breakpoint(xs) {
      font-size: 1rem;
    }
  }
}

.certified-partners {
  @include breakpoint(sm) {
    padding: 0 0 40px;
    margin: 100px 0;
    box-shadow: #cccccc 0px 20px 28px -23px;
  }
  @include breakpoint(xs) {
    margin: 30px 0;
  }
  .box-certified-partners-title {
    color: $primaryColor;
    font-size: 1.5rem;
    @include breakpoint(xs) {
      font-size: 1.5rem;
    }
  }
  .box-certified-partners-des {
    max-width: 230px;
    margin: 0 auto;
    font-size: 1.125rem;
    @include breakpoint(sm) {
      padding: 0 0 50px;
    }
    @include breakpoint(xs) {
      padding-bottom: 10px;
    }
  }
}

.check-authenticity {
  background: rgba(0, 0, 0, 0.02);
  padding: 60px 0;
  border: solid rgba(0, 0, 0, 0.1);
  border-width: 1px 0 1px;
  transition: all 1s;
  .ca-title {
    padding: 0 10px 0;
    .ca-title02 {
      display: block;
      font-size: 1.125rem;
      font-weight: normal;
      color: inherit;
      font-style: italic;
    }
  }
  .text-ca01 {
    padding: 40px 0;
    font-size: 1.125rem;
  }
  .form-check-authenticity {
    @include breakpoint(md) {
      display: flex;
      justify-content: center;
    }
    input {
      margin-right: 20px;
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      padding: 0 10px;
      height: 40px;
      outline: 0;
      min-width: 100%;
      margin-bottom: 10px;
      @include breakpoint(md) {
        min-width: 30%;
        margin-bottom: 0;
      }
    }
    .btn {
      font-size: 1.125rem;
      border-radius: 5px;
      display: block;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  .errow-ca {
    color: red;
    padding-top: 60px;
  }
}

.certified-products {
  background: $primaryColor;
  padding: 60px 0;
  .img-certified-products {
    overflow: hidden;
    border-radius: 15px 15px 0 15px;
    box-shadow: #cccccc 0px 20px 28px -23px;
  }
  .title-healthLoqc {
    color: #ffffff;
    font-size: 2.5rem;
    line-height: initial;
  }
  .link-items-product {
    margin-top: 20px;
    color: $primaryColor;
    font-size: 1.25rem;
    background: #ffffff;
    padding-left: 20px;
    padding-right: 20px;
    &:hover {
      background: $grayColor03;
    }
  }
}

.member-footer-logo {
  a:first-child {
    margin-left: 0;
  }
  a {
    margin-left: 5px;
    background: #ffffff;
    display: inline-block;
    border-radius: 5px;
    padding: 5px 8px;
    &:hover {
      opacity: 0.8;
    }
    @include breakpoint(xs) {
      margin-left: 0;
      margin-top: 10px;
      display: inline-block;
    }
  }
}

.featured-video {
  border: 5px solid rgba(255, 255, 255, 0.5);
  overflow: hidden;
  border-radius: 10px;
  width: 100%;
  video {
    width: 100%;
    display: block;
    cursor: pointer;
  }
}

body > iframe {
  display: none;
}

.bggImgDiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  & > img {
    height: 50px;
  }
  &:last-child {
    margin-top: 30px;
  }
}
