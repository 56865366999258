@import "./../../styles/mixin.scss";
@import "./../../styles/app-variables.scss";
@import "./../../styles/app-constant.scss";
// Profile Buttons
.profile-btn {
  border: unset;
  font-size: 18px;
  line-height: 21px;
  font-weight: $light400;
  font-family: $fontFamily;
  text-transform: capitalize;
  letter-spacing: 1px;
  transition: all 0.5s;
  padding: 16px;
  width: 200px;
  border-radius: 0px;
}
.profile-bg-btn {
  border: 1px solid $lightPrimaryColor;
  background-color: $lightPrimaryColor;
  color: $WhiteColor;
  .MuiCircularProgress-colorPrimary {
    color: $WhiteColor;
  }
  &:hover {
    color: $WhiteColor;
    background-color: $blackColor;
    box-shadow: 0px 0px 15px 0px #0000001a;
    .MuiCircularProgress-colorPrimary {
      color: $primaryColor;
    }
  }
}
.profile-transparent-btn {
  border: 1px solid $primaryColor;
  background-color: transparent;
  color: $primaryColor;
  .MuiCircularProgress-colorPrimary {
    color: $primaryColor;
  }
  &:hover {
    background-color: transparent;
    box-shadow: 0px 0px 15px 0px #0000001a;
  }
}
.profile-sm-btn {
  width: 100%;
  background-color: $WhiteColor;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  padding: 25px 45px;
  text-align: left;
  justify-content: flex-start;
  .MuiCircularProgress-colorPrimary {
    color: $primaryColor;
  }
}
.loginWithFacebookBtn {
  width: 265px;
  height: 40px;
  border-radius: 4px;
  background-color: $facebookColor;
  border: 1px solid $facebookColor;
  color: $WhiteColor;
  letter-spacing: 0;
  svg {
    color: $WhiteColor;
    fill: $WhiteColor;
  }
  &:hover {
    background-color: $facebookColor;
    border: 1px solid $facebookColor;
  }
}
.loginWithGoogleBtn {
  width: 265px;
  height: 40px;
  border-radius: 4px;
  background-color: $WhiteColor;
  border: 1px solid $googleColor;
  color: $googleColor;
  letter-spacing: 0;
  &:hover {
    color: $googleColor;
    background-color: $WhiteColor;
    border: 1px solid $googleColor;
  }
}
.profile-disabled-btn {
  pointer-events: none;
}

// Main Heading CSS
.main-heading {
  color: $blackColor;
  font-size: 34px;
  line-height: 40px;
  font-weight: $bolder700;
  font-family: $fontFamily;
  text-transform: capitalize;
  text-align: center;
  @include sm {
    text-align: left;
  }
}

.sub-heading {
  color: $blackColor;
  font-size: 18px;
  line-height: 21px;
  font-weight: $bolder700;
  font-family: $fontFamily;
  text-transform: capitalize;
}

// Paragraph CSS
.paragraph-root {
  color: $grayColor6E;
  font-size: 18px;
  line-height: 21px;
  font-weight: $light400;
  font-family: $fontFamily;
  @include sm {
    font-size: 16px;
    line-height: 18px;
  }
  @include xs {
    font-size: 14px;
  }
}
// Back Button CSS
.back-btn {
  cursor: pointer;
  border-radius: 20px;
  width: 35px;
  height: 35px;
  background-color: $grayColorD9;
  & > svg:nth-child(1) {
    display: block;
    color: $WhiteColor;
    width: 25px;
    height: 25px;
  }
  & > svg:nth-child(2) {
    display: none;
  }
  &:hover {
    box-shadow: 0px 0px 15px 0px #0000001a;
    background-color: $WhiteColor;
    & > svg:nth-child(1) {
      color: $grayColorD9;
    }
  }
  @include sm {
    width: max-content;
    height: max-content;
    border-radius: unset;
    background-color: unset;
    & > svg:nth-child(1) {
      display: none;
    }
    & > svg:nth-child(2) {
      display: block;
    }
  }
}
.MuiFormLabel-root.Mui-focused {
  color: $blackColor33;
}
.profile-header-root {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 20px 0px 20px;
  box-sizing: border-box;
  & > h1 {
    margin-left: 10px;
    text-align: center;
    flex: 1;
  }
  @include sm {
    padding: 20px 20px 20px 0px;
    & > h1 {
      margin-left: 0px;
    }
  }
}
// Textfield CSS
.textfield-root {
  width: 100%;
  margin-bottom: 15px;
  & > label {
    color: $blackColor33;
    font-size: 18px;
    line-height: 19px;
    font-weight: $light400;
    margin: 0px;
    font-family: $fontFamily;
    @include sm {
      display: none;
    }
  }
  & > div {
    min-height: 40px;
    border: 1px solid $grayColor99;
    border-radius: 5px;
    width: inherit;
    margin-top: 20px;
    padding: 0px;
    & > input,
    & > textarea {
      padding: 8px 10px;
      font-family: $fontFamily;
      font-weight: $light400;
    }
    & > div {
      margin: 0px 8px;
      cursor: pointer;
      & > svg {
        width: 20px;
        height: 20px;
      }
    }
    @include sm {
      border: unset;
      border-radius: unset;
      margin-top: 0px;
      background-color: $WhiteColor;
      height: unset;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
      & > input,
      & > textarea {
        padding: 25px;
        font-size: 14px;
      }
      & > div {
        margin-right: 25px;
      }
    }
  }
}
.switch-textfield {
  & > div {
    @include sm {
      & > input,
      & > textarea {
        padding-right: 5px;
      }
      & > div {
        margin-right: 5px;
      }
    }
  }
}
.textfield-error {
  & > div {
    color: $errorColor;
  }
}
// Select Component CSS
.select-root {
  width: 100%;
  & > label {
    color: $blackColor33;
    font-size: 18px;
    line-height: 19px;
    font-weight: $light400;
    margin: 0px;
    font-family: $fontFamily;
    @include sm {
      display: none;
    }
  }
  & > div {
    height: 40px;
    border: 1px solid $grayColor99;
    border-radius: 5px;
    width: inherit;
    margin-top: 20px;
    & > div {
      &:nth-child(1) {
        padding: 8px 10px;
        font-family: $fontFamily;
        white-space: unset;
        text-transform: capitalize;
        &:focus {
          background-color: transparent;
        }
      }
      &:nth-child(4) {
        cursor: pointer;
        margin: 0px 8px;
        & > svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    & > svg {
      right: 95px;
    }
    @include sm {
      border: unset;
      border-radius: unset;
      margin-top: 0px;
      background-color: $WhiteColor;
      height: unset;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
      & > div {
        &:nth-child(1) {
          font-size: 14px;
          padding: 25px;
          padding-right: 10px;
        }
        &:nth-child(4) {
          margin-right: 25px;
        }
      }
      & > svg {
        right: 51px;
      }
    }
  }
}
.select-box-with-endicon {
  & > div {
    & > svg {
      right: 35px;
    }
  }
}
.mui-select-placeholder {
  & > div > div:nth-child(1) {
    opacity: 0.4;
  }
}
.add-default-svg-style {
  & > div {
    & > svg {
      right: 10px;
    }
  }
}
.select-menuitem {
  text-transform: capitalize;
  font-size: 16px;
}
.switch-select {
  & > div {
    @include sm {
      & > div {
        &:nth-child(4) {
          margin-right: 5px;
        }
      }
      & > svg {
        right: 85px;
      }
    }
  }
}
// Label CSS
.label-root {
  color: $blackColor33;
  font-size: 14px;
  line-height: 19px;
  font-weight: $light400;
  text-transform: capitalize;
  font-family: $fontFamily;
}
// Switch CSS
.switch-root {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: max-content;
    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
      background-color: $primaryColor;
    }
    .MuiSwitch-colorSecondary.Mui-checked {
      color: $primaryColor;
    }
    .MuiSwitch-colorSecondary.Mui-checked:hover {
      background-color: rgba(14, 202, 90, 0.04);
    }
  }
}
// Link Typography CSS
.link-root {
  color: $grayColor6E;
  cursor: pointer;
  text-transform: capitalize;
  &:hover {
    text-decoration: none;
    color: $primaryColor;
  }
}
.linear-progress-root {
  flex-direction: column;
  & > div {
    border-radius: 40px;
    background-color: $grayColorE8;
    height: 25px;
    & > div {
      border-radius: 40px;
      background-color: $primaryColor;
    }
  }
  & > p {
    font-weight: $bolder700;
    font-size: 16px;
    line-height: 19px;
    font-family: $fontFamily;
    margin-top: 5px;
  }
}
// Sidebar CSS
.sidebar-root {
  /*border-radius: 10px;*/
  background-color: $WhiteColor;
  /*box-shadow: 0px 0px 15px 0px #0000000d;*/
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  height: auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  .sidebar-healthloq-icon {
    margin: 20px auto;
    & > a {
      & > img {
        width: 60px;
        height: 60px;
      }
    }
    @include sm {
      display: none;
    }
  }
  .sidebar-icon-root {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 0px;
    border-bottom: 1px solid $borderColor;
    cursor: pointer;
    &:last-child {
      border-bottom: unset;
    }
    & > svg {
      fill: $blackColor;
      width: 20px;
      height: 20px;
      margin-right: 20px;
    }
    & > div {
      width: calc(100% - 40px);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      p {
        margin: 0px;
        text-transform: capitalize;
        margin-right: 5px;
      }
    }
    &:hover {
      & > svg {
        fill: $primaryColor;
      }
      p {
        color: $primaryColor;
      }
    }
  }
  .active {
    & > svg {
      fill: $primaryColor;
    }
    p {
      color: $primaryColor;
    }
  }
  .disabled {
    pointer-events: none;
    & > svg {
      fill: $disabledColor;
    }
    p {
      color: $disabledColor;
    }
  }
}
// Right side CSS
.right-side-root {
  border-radius: 10px;
  background-color: $WhiteColor;
  // box-shadow: 0px 0px 15px 0px #0000000d;
  height: auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-left: 20px;
  box-sizing: border-box;
}
// Category Box CSS
.category-box {
  width: 100px;
  height: 100px;
  border: 2px solid $grayColorB0;
  cursor: pointer;
  flex-direction: column;
  margin-bottom: 15px;
  margin-right: 15px;
  position: relative;
  box-sizing: border-box;
  & > img {
    width: 30px;
    height: 30px;
  }
  & > p {
    color: $grayColorB0;
    font-size: 12px;
  }
  .selected-cat-icon {
    position: absolute;
    bottom: -1px;
    right: -1px;
    width: 30px;
    height: 30px;
    background-color: $primaryColor;
    & > svg {
      width: 20px;
      height: 20px;
      color: $WhiteColor;
    }
  }
}
// SM Profile page footer CSS
.profile-page-footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 75px;
  box-shadow: $grayColor02 0px 0px 20px;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    & > svg {
      width: 25px;
      height: 25px;
      fill: $blackColor;
    }
    & > p {
      text-transform: capitalize;
      margin: 0;
      padding: 0;
    }
  }
  .active {
    & > svg {
      fill: $primaryColor;
    }
    & > p {
      color: $primaryColor;
    }
  }
  .disabled {
    pointer-events: none;
    & > svg {
      fill: $disabledColor;
    }
    & > p {
      color: $disabledColor;
    }
  }
}
// Organization Box CSS
.organization-box {
  flex-direction: column;
  cursor: pointer;
  width: auto;
  height: 100px;
  border: 1px solid $borderColor;
  position: relative;
  box-sizing: border-box;
  padding: 10px;
  & > img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-bottom: 5px;
  }
  & > p {
    font-size: 14px;
    font-weight: $light400;
    font-family: $fontFamily;
    margin: 0px;
  }
  .selected-org-icon {
    position: absolute;
    bottom: -1px;
    right: -1px;
    width: 20px;
    height: 20px;
    background-color: $primaryColor;
    & > svg {
      width: 15px;
      height: 15px;
      color: $WhiteColor;
    }
  }
  .organization-box-offer {
    position: absolute;
    top: -1px;
    left: -1px;
    width: max-content;
    height: max-content;
    background-color: $errorColor;
    color: $WhiteColor;
    font-size: 12px;
    border-radius: 0 0 10px 0;
    padding: 0 5px;
  }
}
.selected-org {
  border: 1px solid $primaryColor;
}
.disabled-org {
  pointer-events: none;
  border: 1px solid $borderColor;
  & > p {
    color: $disabledColor;
  }
  .selected-org-icon {
    background-color: $borderColor;
  }
}
// Badge CSS
.mui-badge {
  & > span {
    background-color: $primaryColor;
    color: $WhiteColor;
  }
}
// Accordion CSS
.accordion-root {
  margin: 0;
  &::before {
    background-color: $primaryColor;
  }
  .accordion-summary {
    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    @include sm {
      & > div {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        & > p {
          font-size: 18px;
          line-height: 23px;
          &:first-child {
            margin-bottom: 10px;
          }
        }
      }
    }
    @include xs {
      & > div > p {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  .accordion-expanded {
    background-color: $primaryColor;
    & > div {
      color: $WhiteColor;
      & > p {
        color: $WhiteColor;
      }
    }
  }
  .accordion-expanded-grey {
    background-color: $grayColorDE;
    & > div {
      color: $blackColor;
    }
  }
  .accordion-details {
    display: flex;
    flex-direction: column;
    & > div {
      &:first-child {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        & > p {
          font-size: 16px;
          & > span {
            font-weight: 600;
            margin-right: 5px;
          }
        }
      }
    }
    .surveyQAns {
      display: flex;
      flex-direction: column;
      & > div {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        & > p {
          &:first-child {
            font-weight: 600;
          }
          &:last-child > span {
            font-weight: 600;
            margin-right: 5px;
          }
        }
      }
    }
  }
}

/*switch button*/
.form-group.switch_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0px;
}
.form-group.switch_wrapper > label {
  width: auto;
}
// .form-group.switch_wrapper .button-switch {
//   margin-left: 15px;
// }

.button-switch {
  font-size: 12px;
  height: 22px;
  margin: 0;
  position: relative;
  width: 6.7em;
}
.button-switch .lbl-off,
.button-switch .lbl-on {
  cursor: pointer;
  display: block;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 600;
  position: absolute;
  top: 4px;
  -webkit-transition: opacity 0.25s ease-out 0.1s;
  -o-transition: opacity 0.25s ease-out 0.1s;
  transition: opacity 0.25s ease-out 0.1s;
  text-transform: capitalize;
  margin: 0;
}
.button-switch .lbl-off {
  right: 18px;
  color: #fff;
}
.button-switch .lbl-on {
  color: #fff;
  opacity: 0;
  left: 9px;
}
.button-switch .switch {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 0;
  font-size: 12px;
  left: 0;
  line-height: 0;
  outline: none;
  position: absolute;
  top: 0;
  width: 0;
  padding: 0;
  border: 0;
}
.button-switch .switch:before,
.button-switch .switch:after {
  content: "";
  font-size: 1em;
  position: absolute;
}
.button-switch .switch:before {
  border-radius: 1.25em;
  background: #bdc3c7;
  height: 22px;
  left: 0;
  top: 0;
  -webkit-transition: background-color 0.25s ease-out 0.1s;
  -o-transition: background-color 0.25s ease-out 0.1s;
  transition: background-color 0.25s ease-out 0.1s;
  width: 67px;
}
.button-switch .switch:after {
  -webkit-box-shadow: 0 0.0625em 0.375em 0 #666;
  box-shadow: 0 0.0625em 0.375em 0 #666;
  border-radius: 50%;
  background: #fefefe;
  height: 16px;
  -webkit-transform: translate(3px, 3px);
  -ms-transform: translate(3px, 3px);
  transform: translate(3px, 3px);
  -webkit-transition: -webkit-transform 0.25s ease-out 0.1s;
  transition: -webkit-transform 0.25s ease-out 0.1s;
  -o-transition: transform 0.25s ease-out 0.1s;
  transition: transform 0.25s ease-out 0.1s;
  transition: transform 0.25s ease-out 0.1s, -webkit-transform 0.25s ease-out 0.1s;
  width: 16px;
}
.button-switch .switch:checked:after {
  -webkit-transform: translate(48px, 3px);
  -ms-transform: translate(48px, 3px);
  transform: translate(48px, 3px);
}
.button-switch .switch:checked ~ .lbl-off {
  opacity: 0;
}
.button-switch .switch:checked ~ .lbl-on {
  opacity: 1;
}
.button-switch .switch:before {
  background: $grayColor99;
}
.button-switch .switch:checked:before {
  background: $primaryColor;
}
.master-toggle-switch {
  .button-switch .switch::before {
    width: 85px;
  }
  .button-switch .switch:checked:after {
    -webkit-transform: translate(66px, 3px);
    -ms-transform: translate(66px, 3px);
    transform: translate(66px, 3px);
  }
  .button-switch {
    width: 8.2em;
  }
}
.connection-toggle-switch {
  justify-content: center;
  .button-switch {
    width: 8.7em;
  }
  .button-switch .switch::before {
    width: 110px;
  }
  .button-switch .switch:checked:after {
    -webkit-transform: translate(90px, 3px);
    -ms-transform: translate(90px, 3px);
    transform: translate(90px, 3px);
  }
  .button-switch .lbl-on {
    left: 15px;
  }
  .button-switch .lbl-off {
    right: 0px;
  }
}
.master-connection-toggle-switch {
  justify-content: center;
  .button-switch {
    width: 10em;
  }
  .button-switch .switch::before {
    width: 125px;
  }
  .button-switch .switch:checked:after {
    -webkit-transform: translate(105px, 3px);
    -ms-transform: translate(105px, 3px);
    transform: translate(105px, 3px);
  }
  .button-switch .lbl-on {
    left: 13px;
  }
  .button-switch .lbl-off {
    right: 0px;
  }
}
/*End switch button*/

.custom-btn {
  color: white !important;
  background-color: $lightPrimaryColor;
  font-weight: bold;
  line-height: 140%;
  letter-spacing: 0.2px;
  border: none;
  text-transform: none;
}

.custom-btn:hover {
  background-color: #000 !important;
  color: white !important;
  border: none;
}