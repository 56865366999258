@import "./../../../styles/mixin.scss";
@import "./../../../styles/app-variables.scss";
@import "./../../../styles/app-constant.scss";

// Sm Footer tabs CSS
.sm-footer-tabs-root {
  display: none;
  background-color: $WhiteColor;
  box-shadow: 0 1px 6px 0 #20212447;
  @include sm {
    display: block;
  }
  .MuiTab-root {
    width: calc(100% / 3);
    max-width: unset;
    font-size: 14px;
    font-weight: 500;
    font-family: $fontFamily;
  }
  & > div > div > span {
    background-color: $primaryColor;
  }
}

// Origin page organization info section CSS
.title-attachments {
  background: $WhiteColor;
  box-shadow: $grayColor02 0px 20px 28px -23px;
  padding: 7px 15px;
  min-height: 69px;
  display: flex;
  justify-content: space-between;
  position: relative;
  .bottom-btns {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    top: 79px;
    right: 0px;
    z-index: 2;
  }
  .bottom-btns > .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    color: $WhiteColor;
    border-right: 0;
    background-color: #007937b5 !important;
    &:hover {
      & > span {
        font-size: 14px;
        transition: 0.2s;
        margin-left: 10px;
      }
    }
  }

  .bottom-btns > .btn svg {
    margin-right: 0px;
    width: 20px;
    height: 20px;
  }

  .bottom-btns > .btn svg path {
    fill: $WhiteColor;
  }

  .bottom-btns > .btn span {
    font-size: 0;
    transition: 0.2s;
    margin-left: 0;
  }

  a {
    color: $blackColor02;
    font-weight: bold;
    &:hover {
      text-decoration: none;
      color: $primaryColor;
    }
  }
  .icon-attachments {
    border-radius: 100%;
    overflow: hidden;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-weight: bold;
    font-size: 0.875rem;
    text-transform: uppercase;
    border: 1px solid $grayColor02;
    img {
      max-width: 30px;
      max-height: 30px;
    }
  }
  .icon-location {
    background-color: #007937b5 !important;
    color: $WhiteColor;
    width: 55px;
    height: 55px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 0;
    &:hover {
      opacity: 0.8;
      text-decoration: none;
      color: $WhiteColor;
    }
    & > svg {
      width: 25px;
      height: 25px;
      fill: $WhiteColor;
    }
  }
}

// Manage Product Leftside CSS
.sm-product-leftside-root {
  width: 0px;
  max-width: 500px;
  background-color: $WhiteColor;
  z-index: 3;
  height: calc(100% - 48px);
  overflow: auto;
  transition: all 0.5s;
  position: absolute;
}
.sm-product-leftside-show {
  width: 100%;
}
