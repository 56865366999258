@import "./../../styles/app-constant.scss";
@import "./../../styles/app-variables.scss";
@import "./../../styles/mixin.scss";
// Dialog common component CSS
.dialog-root {
  @include xs {
    .MuiDialog-paper {
      margin: 15px;
    }
  }
}
.dialog-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $grayColor02;
  & > div {
    flex: unset;
  }
  & > svg {
    cursor: pointer;
    margin-right: 24px;
  }
}
.dialog-content {
  padding: 10px 24px;
}
.dialog-actions {
  padding: 16px 24px;
  border-top: 1px solid $grayColor02;
}
.dialog-actions-buttons {
  text-transform: capitalize;
  background-color: $primaryColor;
  border: 1px solid $primaryColor;
  color: $WhiteColor;
  transition: all 0.5s;
  padding: 6px 15px;
  &:hover {
    background-color: transparent;
    color: $primaryColor;
  }
}
.dialog-secondary-action-button {
  background-color: $WhiteColor;
  color: $primaryColor;
}

// Document to be verified dialog CSS
.mb20 {
  margin-bottom: 20px;
}
.select-box {
  width: 100%;
  min-width: 50%;
  max-width: max-content;
  padding: 5px 10px;
  min-height: 40px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.headerBlockchainVerifyProofBox {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.upload-btn {
  border-radius: 5px;
  padding: 8px 15px;
  background-color: #53da00;
  color: #fff;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
}
.upload-btn > img {
  margin-right: 10px;
}

.upload-btn > div {
  margin-left: 10px;
}

.uploadedImageName {
  margin-left: 10px;
  color: #ccc;
}

.blockchainVerifyErrorMsg {
  color: #ff7578;
}

.loaderDiv {
  margin-left: 10px;
}

.blockchainVerifySuccessMsg {
  color: #53da00;
}

.blockchainVerifyErrorMsg,
.blockchainVerifySuccessMsg {
  margin: 0px;
}

.msgIcon {
  margin-right: 10px;
  width: 25px;
  height: 25px;
}

.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.select-box-div {
  width: 100%;
}

@media (max-width: 768px) {
  .select-box {
    max-width: 90%;
    min-width: 90%;
    overflow: auto !important;
  }
  .upload-file-div {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .uploadedImageName {
    margin-left: 0px;
  }
}
.share-icon-main1 {
  & > svg {
    &:hover {
      fill: rgb(14, 202, 90);
    }
  }
}
.profile-page-share-main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  .profile-page-header-div {
    & > a {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .profile-page-share-main {
    flex-wrap: wrap;
    .profile-page-header-div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      & > a {
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
        & > img {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
  .profile-page-share-main > div:first-child {
    order: 2;
    width: 100%;
  }
}

.profile-page-share {
  cursor: pointer;
  color: rgb(14, 202, 90);
  position: relative;
  display: flex;
  margin-right: auto;
  align-items: flex-start;
  & svg {
    height: 17px;
    width: 17px;
  }
  &.share-btn-active {
    .st-inline-share-buttons {
      transition: 0.5s !important;
      width: 152px;
      overflow: hidden;
      display: flex !important;
    }
  }
  .st-inline-share-buttons {
    display: flex !important;
    width: 0;
    transition: 0.5s !important;
    overflow: hidden;
    z-index: auto !important;
    & div {
      display: inline-block !important;
    }
  }
}
.share-link-section {
  & div {
    display: flex;
    align-items: center;
    justify-content: end;
    .facebook-icon {
      border: 1px solid #3b5998;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      background-color: #3b5998;
      & svg {
        fill: #fff;
      }
      &:hover {
        background-color: transparent;
        & svg {
          fill: #3b5998;
        }
      }
    }
    .linkedin-icon {
      border: 1px solid #0a66c2;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      background-color: #0a66c2;
      & svg {
        fill: #fff;
      }
      &:hover {
        background-color: transparent;
        & svg {
          fill: #0a66c2;
        }
      }
    }
    .twitter-icon {
      border: 1px solid rgb(23, 124, 192);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      background-color: rgb(23, 124, 192);
      & svg {
        fill: #fff;
      }
      &:hover {
        background-color: transparent;
        & svg {
          fill: rgb(23, 124, 192);
        }
      }
    }
    .whatsapp-icon {
      border: 1px solid #4fc65d;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      background-color: #4fc65d;
      & svg {
        fill: #fff;
      }
      &:hover {
        background-color: transparent;
        & svg {
          fill: #4fc65d;
        }
      }
    }
    .share-icon-main:not(:last-child) {
      margin-right: 10px;
    }
  }
  & svg {
    overflow: hidden;
    vertical-align: middle;
    height: 20px;
    width: 20px;
  }
}
.share-btn {
  border: unset;
  font-size: 16px;
  line-height: 21px;
  font-weight: $light400;
  letter-spacing: 1px;
  transition: all 0.5s;
  padding: 4px 8px;
  width: auto;
  border-radius: 5px;
  margin-right: 8px;

  border: 1px solid $primaryColor;
  background-color: $lightPrimaryColor;
  color: $WhiteColor;
  .MuiCircularProgress-colorPrimary {
    color: $WhiteColor;
  }
  &:hover {
    color: $WhiteColor;
    background-color: $blackColor;
    box-shadow: 0px 0px 15px 0px #0000001a;
    .MuiCircularProgress-colorPrimary {
      color: $primaryColor;
    }
    & svg {
      fill: $WhiteColor;
    }
  }
}

.document-hash-detail {
  width: 100%;
  display: flex;
  flex-direction: column;
  h4 {
    &:nth-child(1) {
      margin-bottom: 10px;
    }
    font-family: $fontFamily;
    font-weight: $bold600;
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & > div {
      &:nth-child(1) {
        width: 60px;
        height: 60px;
        margin-right: 10px;
        & > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 50%;
        }
      }
      &:nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        h6 {
          font-family: $fontFamily;
          font-weight: $bold600;
          margin-bottom: 0;
          margin-right: 5px;
        }
        p {
          font-family: $fontFamily;
          text-transform: capitalize;
          margin-bottom: 0;
        }
      }
    }
  }
  & > p {
    span {
      font-weight: $bold600;
    }
  }
}
.blockchain-proof-ul {
  width: 100%;
  &>p {
    line-break: anywhere;
  }
  & > div:not(:last-child) {
    margin-bottom: 100px;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      width: 2px;
      height: 100px;
      background-color: $primaryColor;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
    &::after {
      position: absolute;
      content: "";
      width: 50px;
      height: 50px;
      top: calc(100% + 25px);
      background-color: $primaryColor;
      outline: 2px solid $WhiteColor;
      border-radius: 50%;
      left: 50%;
      transform: translateX(-50%);
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z' fill='white' /%3E%3C/svg%3E");
    }
  }
  .blockchain-proof-expired:not(:last-child){
    &::before{
      background-color: #FFAA1D;
    }
    &::after{
      background-color: #FFAA1D;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h2c0-1.66 1.34-3 3-3s3 1.34 3 3v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z' fill='white' /%3E%3C/svg%3E")
    }
  }
  .blockchain-proof-expired-new:not(:last-child){
    &::before{
      background-color: #D6513F;
    }
    &::after{
      background-color: #D6513F;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h2c0-1.66 1.34-3 3-3s3 1.34 3 3v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z' fill='white' /%3E%3C/svg%3E")
    }
  }
  .blockchain-proof-error-msg:not(:last-child){
    &::before{
      background-color: $errorColor;
    }
    &::after{
      background-color: $errorColor;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h2c0-1.66 1.34-3 3-3s3 1.34 3 3v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z' fill='white' /%3E%3C/svg%3E")
    }
  }
  & > p {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 10px 0;
    & > div {
      margin-left: 5px;
    }
  }
}
