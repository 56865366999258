@import "./../../styles/mixin.scss";
@import "./../../styles/app-variables.scss";
@import "./../../styles/app-constant.scss";

.page-contact {
    background: $disabledColor;
    min-height: 100vh;
    .map-office-address {
        border: 0;
        display: block;
    }
    .contact-body {
        .map-container {
            height: 40vh;
            border-top: 1px solid $borderColor;
        }
        i.material-icons {
            min-width: 20px;
            display: inline-block;
            text-align: right;
            margin-right: 15px;
            opacity: 0.5;
            @include breakpoint(xs) {
                margin-right: 10px;
            }
        }
        .section-address {
            min-height: calc(60vh - 100px);
            padding: 0 10px;
            p {
                margin-bottom: 0;
            }
            .title-address {
                font-size: 2rem;
                font-weight: bold;
                color: $primaryColor;
                @include breakpoint(xs) {
                    font-size: 1rem;
                }
            }

            .txt-address-full {
                font-size: 1.75rem;
                padding: 10px 0;
                font-weight: bold;
                display: flex;
                align-items: center;
                @include breakpoint(xs) {
                    font-size: 0.875rem;
                    align-items: flex-start;
                }
                i.material-icons {
                    margin-right: 5px;
                }
            }
            .txt-email-contact {
                line-height: initial;
                a {
                    display: flex;
                    align-items: flex-end;
                    &:hover {
                        text-decoration: none;
                    }
                    .material-icons {
                        font-size: 1rem;
                    }
                    color: $blackColor;
                    font-weight: bold;
                }
            }
        }
    }
}
