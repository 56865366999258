@import "./../../styles/app-constant.scss";
@import "./../../styles/app-variables.scss";
@import "./../../styles/mixin.scss";

.register-container,
.login-container {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .auth-rightside {
    width: 100%;
    max-width: 700px;
    margin: 20px auto;
    background-color: $grayColor03;
    border-radius: 20px;
    box-shadow: 0 0 55px 0 rgba(0, 0, 0, 0.1);
    padding: 20px 60px;
    font-family: $fontFamily;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .auth-backbtn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      & > svg {
        margin-right: 10px;
      }
      & > span {
        font-size: 16px;
        line-height: 19px;
        color: $blackColor;
        font-weight: $medium500;
      }
    }
    .auth-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .auth-main-heading {
      color: $blackColor;
      font-weight: $bold600;
      margin-bottom: 10px;
    }
    .auth-main-text {
      font-size: 18px;
      line-height: 21px;
      color: $blackColor66;
      font-weight: $light400;
      margin-bottom: 20px;
    }
    .authlinkbox {
      margin-bottom: 20px;
    }
    .auth-login-link {
      font-size: 16px;
      line-height: 20px;
      font-weight: $medium500;
      color: $blackColor;
      margin-bottom: 20px;
      & > a {
        color: $primaryColor;
        cursor: pointer;
      }
    }
    .auth-footer-links {
      display: flex;
      & > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        & > a {
          color: $blackColor;
          font-size: 14px;
          line-height: 18px;
          font-weight: $lighter300;
          color: $grayColor99;
          &:hover {
            color: $primaryColor;
          }
        }
        & > span {
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: $primaryColor;
          margin: 0px 10px;
        }
      }
    }
  }
  @include sm {
    background-image: unset !important;
    .auth-rightside {
      margin: 0;
      padding: 20px;
      border-radius: unset;
      max-width: 100%;
      height: 100%;
      .auth-backbtn {
        margin-bottom: 10px;
      }
    }
  }
}
