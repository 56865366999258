@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $class == sm {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $class == md {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $class == lg {
    @media (min-width: 1200px) {
      @content;
    }
  } @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg";
  }
}

$breakpoints: (
  "xs": 600px,
  "sm": 768px,
  "md": 1200px,
  "lg": 1400px,
  "xl": 1600px,
);

@mixin xs {
  @media (max-width: map-get($breakpoints,"xs")) {
    @content;
  }
}

@mixin sm {
  @media (max-width: map-get($breakpoints,"sm")) {
    @content;
  }
}

@mixin md {
  @media (max-width: map-get($breakpoints,"md")) {
    @content;
  }
}

@mixin lg {
  @media (max-width: map-get($breakpoints,"lg")) {
    @content;
  }
}

@mixin xl {
  @media (max-width: map-get($breakpoints,"xl")) {
    @content;
  }
}

@mixin upXs {
  @media (min-width: map-get($breakpoints,"xs")) {
    @content;
  }
}

@mixin upSm {
  @media (min-width: map-get($breakpoints,"sm")) {
    @content;
  }
}

@mixin upMd {
  @media (min-width: map-get($breakpoints,"md")) {
    @content;
  }
}

@mixin upLg {
  @media (min-width: map-get($breakpoints,"lg")) {
    @content;
  }
}

@mixin upXl {
  @media (min-width: map-get($breakpoints,"xl")) {
    @content;
  }
}

@mixin up($bp: 0) {
  @media (min-width: $bp) {
    @content;
  }
}

@mixin down($bp: 0) {
  @media (max-width: $bp) {
    @content;
  }
}

@mixin between($lbp: 0, $hbp: 0) {
  @media (min-width: $lbp) and (max-width: $hbp) {
    @content;
  }
}
