// ********************************************************* Swiper Slider CSS *********************************************************
.swiperContainer {
    width: 100%;
    padding: 0 5px;
    .swiper {
        padding: 0 0 15px;
        width: calc(100% - 106px);
        .swiper-pagination {
            bottom: 0px;
        }
        .swiper-slide {
            width: max-content;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .swiperBtnDisabled {
        opacity: 0.5;
        cursor: initial;
    }
}
