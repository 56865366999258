@import "./../../styles/mixin.scss";
@import "./../../styles/app-variables.scss";
@import "./../../styles/app-constant.scss";

.product-list {
  background: rgba($color: #000000, $alpha: 0.03);
  min-height: 100vh;
  .head-product-list {
    padding: 0 15px;
    line-height: initial;
    border-bottom: 1px solid #f0f0f0;
    .text-authenticates {
      padding-bottom: 15px;
      font-size: 0.875rem;
    }
    .head-title-product-list {
      .list-sort-by {
        margin-bottom: 10px;
      }
      .text-show {
        font-size: 0.75rem;
        padding-bottom: 10px;
        margin-bottom: 10px;
      }
      .sortByWeb {
        display: block;
      }
      .sortby-li {
        display: none;
        & > select {
          border: none;
          &:focus {
            outline: none;
          }
          & > option {
            &:active {
              background-color: $primaryColor !important;
            }
          }
        }
      }
      @media (max-width: 1000px) {
        flex-direction: column;
        align-items: flex-start !important;
      }
    }
    .list-sort-by {
      font-size: 0.875rem;
      li {
        padding-bottom: 10px;
        position: relative;
        &:first-child {
          font-weight: bold;
        }
        &:not(:first-child) {
          margin-left: 20px;
          cursor: pointer;
          &.active {
            color: $primaryColor;
            font-weight: bold;
            cursor: auto;
            &:after {
              content: "";
              width: 100%;
              height: 3px;
              background: $primaryColor;
              position: absolute;
              left: 0;
              bottom: 0;
            }
          }
        }
      }
    }
    .breadcrumb01 {
      font-size: 12px;
      padding: 15px 0;
      margin: 0;
      li {
        a {
          color: $primaryColor;
        }
        &:not(:last-child) {
          display: flex;
          align-items: center;
          &:after {
            content: "";
            display: inline-block;
            width: 6px;
            height: 6px;
            border: solid rgba($color: #000000, $alpha: 0.5);
            border-width: 1px 1px 0 0;
            transform: rotate(45deg);
            margin: 0 10px;
          }
        }
      }
    }
  }
  .product-list-body {
    padding-top: 15px;
  }
  .no-data-found {
    padding: 15px;
  }
  p {
    margin-bottom: 0;
  }
  .title-product-list {
    padding: 20px 0 10px;
    p {
      font-size: 1.5rem;
      line-height: initial;
      color: $primaryColor;
    }
    span {
      font-size: 0.875rem;
    }
  }
  .left-nav-product {
    background: #ffffff;
    box-shadow: 0 1px 8px 0 #0000000f;
    width: calc(100% - 15px);
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 20px;
    }
    .so-title-clear {
      font-size: 0.875rem;
      color: blue;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .lnp-search-section {
      input {
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 0.875rem;
        padding: 6px 10px;
        width: 100%;
        &:focus {
          outline: 0;
        }
      }
      .btn-lnp {
        margin-left: 10px;
        background: #0000000f;
        font-size: 0.875rem;
      }
    }
    .lnp-item {
      border-bottom: 1px solid #f0f0f0;
    }
    .lnp-item-space {
      padding: 15px;
    }
    .lnp-sub-title {
      font-size: 0.75rem;
      font-weight: bold;
      padding-bottom: 10px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & > div {
        margin-left: 5px;
      }
    }
    .lnp-title {
      font-size: 1.125rem;
      padding: 10px 15px;
      border-bottom: 1px solid #f0f0f0;
    }
    .section-organization {
      padding: 15px;
      font-size: 0.875rem;
      .so-title {
        .so-title-text {
          font-weight: bold;
        }
      }
      .link-brand-name {
        line-height: initial;
        margin: 0 10px 10px 15px;
        text-transform: capitalize;
        &:last-child {
          margin-bottom: 0;
        }
        &.active {
          p {
            color: $primaryColor;
            font-weight: bold;
            cursor: none;
            pointer-events: none;
          }
        }
        p {
          color: #000000;
          display: flex;
          align-items: center;
          cursor: pointer;
          &::before {
            content: "";
            width: 6px;
            height: 6px;
            display: block;
            background: $primaryColor;
            border-radius: 100%;
            margin-right: 10px;
          }
        }
      }
    }
    .product-type-select {
      width: 100%;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      font-size: 0.875rem;
      & > div {
        padding: 6px 24px 6px 10px;
      }
    }
    //box-shadow: 0 1px 8px 0 #0000000f;
  }
  .section-items {
    background: $WhiteColor;
    box-shadow: 0 1px 8px 0 #0000000f;
    margin: 0 0 20px;
    // .col-lg-3:nth-child(-n + 4) {
    //   .item-productlist {
    //     border-top-width: 1px;
    //   }
    // }
    .text-organization {
      color: #878787;
      font-size: 0.75rem;
      text-transform: capitalize;
    }
    .item-productlist {
      //background: $WhiteColor;
      //border: 1px solid #f0f0f0;
      //border-top-width: 0;
      height: 100%;
      //margin-left: -1px;
      position: relative;
      .img-productlist {
        height: 300px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        border-radius: 5px 5px 0 0;
        img {
          max-width: 90%;
          max-height: 100%;
          width: 90%;
        }
      }
      &:hover {
        box-shadow: 0 3px 16px 0 #0000001c;
      }
    }
    .text-productlist {
      padding: 10px;
      line-height: initial;
      // .text-organization {
      //   font-size: 0.75rem;
      //   font-weight: bold;
      //   position: absolute;
      //   top: 5px;
      //   left: 0;
      //   color: $primaryColor;
      //   background: #edfbe5;
      //   padding: 3px 10px;
      //   border-radius: 0 3px 3px 0;
      // }
      .product-title {
        font-weight: bold;
        font-size: 0.875rem;
        cursor: pointer;
        color: $primaryColor;
        line-height: initial;
        display: inline-block;
        padding: 5px 0;
        &:hover {
          color: $blackColor02;
          text-decoration: none;
        }
      }
      .product-des {
        font-size: 0.875rem;
      }
    }
    .product-links {
      padding: 10px;
      justify-content: space-between;
      .link {
        background: $lightPrimaryColor;
        color: #ffffff;
        width: 48%;
        text-align: center;
        border-radius: 3px;
        padding: 5px 0;
        font-size: 0.875rem;
        cursor: pointer;
        &.disable-but {
          background: rgba($color: #000000, $alpha: 0.3);
          pointer-events: none;
        }
        &:hover {
          background: $blackColor;
          text-decoration: none;
        }
      }
    }
  }
  .product-pagination {
    padding: 30px 0;
    line-height: initial;
  }
  .rc-pagination-item-active,
  .rc-pagination-item-active:focus,
  .rc-pagination-item-active:hover,
  .rc-pagination-item:focus,
  .rc-pagination-item:hover,
  .rc-pagination-prev:focus .rc-pagination-item-link,
  .rc-pagination-next:focus .rc-pagination-item-link,
  .rc-pagination-prev:hover .rc-pagination-item-link,
  .rc-pagination-next:hover .rc-pagination-item-link {
    border-color: $primaryColor;
    background: $primaryColor;
    color: #ffffff;
  }
  .rc-pagination-prev button:after,
  .rc-pagination-next button:after {
    line-height: 0;
    font-size: 20px;
  }
  .rc-pagination-disabled .rc-pagination-item-link,
  .rc-pagination-disabled:hover .rc-pagination-item-link,
  .rc-pagination-disabled:focus .rc-pagination-item-link,
  .rc-pagination-disabled,
  .rc-pagination-disabled:hover,
  .rc-pagination-disabled:focus {
    pointer-events: none;
  }
  .rc-pagination-item,
  .rc-pagination-prev,
  .rc-pagination-next,
  .rc-pagination-jump-prev,
  .rc-pagination-jump-next {
    font-size: 0.75rem;
  }
}

@media (max-width: 500px) {
  .sortByWeb {
    display: none !important;
  }
  .sortby-li {
    display: block !important;
  }
}
.toggleProductFilterAccordion {
  & > div {
    &:nth-child(2) {
      display: none;
    }
    @include sm {
      &:nth-child(1) {
        display: none;
      }
      &:nth-child(2) {
        display: block;
      }
    }
  }
  .traits-accordion-root {
    padding: 0px;
    margin: 0px;
    margin-bottom: 15px !important;
    .accordionSummary {
      padding: 15px;
      min-height: unset;
      border-bottom: 1px solid #f0f0f0;
      & > div {
        margin: 0px;
        padding: 0px;
        & > p {
          border: none;
          padding: 0px;
        }
      }
    }
    .accordionDetails {
      padding: 0px;
      flex-direction: column;
    }
  }
}
