@import "./../../styles/app-constant.scss";
@import "./../../styles/app-variables.scss";
@import "./../../styles/mixin.scss";
.page-404 {
    min-height: 100vh;
    @include breakpoint(xs) {
        padding: 30px 0;
    }
    .text-unfortunately {
        font-size: 2.25rem;
        max-width: 70%;
        padding: 30px 0;
        margin: 0 auto;
        line-height: initial;
        @include breakpoint(xs) {
            font-size: 1.25rem;
        }
    }
    .btn-success {
        font-size: 1.5rem;
        padding-left: 50px;
        padding-right: 50px;
    }

    .image-404 {
        width: 300px;
        .error-code {
            font-size: 80px;
            fill: $WhiteColor;
        }
        .cls-1 {
            fill: #43af00;
        }
        .cls-2 {
            fill: $primaryColor;
        }
        .cls-3 {
            fill: #c3d4d8;
        }
        .cls-4 {
            fill: #e6f7dc;
        }
        .cls-5 {
            fill: $primaryColor;
        }
        .cls-6 {
            fill: #a2b7bc;
        }
        .cls-7 {
            fill: #a9a9a9;
        }
        .cls-8 {
            fill: #e6f7dc;
        }
        .cls-9 {
            fill: #f197be;
        }
        .cls-10 {
            fill: #565656;
        }
        .cls-11 {
            fill: #fff;
        }
        .cls-12 {
            fill: #e9eff2;
        }
    }
}
