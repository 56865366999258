@import "./../../styles/app-constant.scss";
@import "./../../styles/app-variables.scss";
@import "./../../styles/mixin.scss";

:root {
  --bad-color: #ff5722;
  --ok-color: #ff9800;
  --good-color: #36d896;
  --great-color: #3f51b5;
  --track-color: #eaeef4;
}

.profile-container {
  min-height: calc(var(--vh, 1vh) * 100);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $WhiteColor;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include sm {
    height: calc(var(--vh, 1vh) * 100);
  }
}

.signup-profile-container {
  @include sm {
    height: 100%;
  }
}

.profile-container-disableScroll {
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  .profile-root .profile-page-content {
    overflow: hidden;
  }
}

.profile-desktop-view-content {
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  min-height: 100vh;
  box-sizing: border-box;
  flex-wrap: nowrap;
  @include down(1210px) {
    padding: 0px 20px;
  }
}
.profile-content {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.profile-msg-box {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  border: 2px solid $primaryColor;
  color: $primaryColor;
  p {
    margin: 0;
  }
}
.profile-center {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: $WhiteColor;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.pc-mobile {
  padding: 0px;
}
.pc-desktop {
  max-width: 720px;
  border-radius: 20px;
  box-shadow: 0px 0px 55px 0px #0000001a;
  margin: 30px 0px;
  position: relative;
  & > div:nth-child(2) {
    padding: 20px 60px 50px;
  }
}
.profile-heading {
  font-size: 34px;
  font-weight: $bolder700;
  line-height: 40px;
  font-family: $fontFamily;
  text-align: center;
  color: $blackColor;
  margin: 0px;
  @include sm {
    padding: 0px 30px;
  }
}
// Create/update pass code page CSS
.ac-root {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 50px 60px;
  .ac-content {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .ac-form-div {
      width: 100%;
      margin: 15px 0px;
      & > div:nth-child(2) {
        margin-top: 15px;
      }
    }
    .ac-rememberme-div {
      margin-top: -15px;
      margin-bottom: 15px;
      width: 100%;
      & > label {
        margin: 0;
        & > span {
          &:nth-child(1) {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 0;
          }
          &:nth-child(2) {
            color: $blackColor33;
            font-weight: $light400;
            font-size: 16px;
            line-height: 18px;
            font-family: $fontFamily;
          }
        }
      }
    }
  }
  .ac-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & > button {
      &:nth-child(2) {
        margin-top: 15px;
      }
    }
  }
  @include sm {
    padding: 0px;
    .ac-content {
      & > h1 {
        padding: 20px;
      }
    }
    .ac-footer {
      margin-bottom: 30px;
    }
    .ac-rememberme-div {
      padding: 0 20px;
    }
  }
}
// Create Profile Page CSS
.create-profile-root {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 60px 50px;
  & > div:nth-child(2) {
    margin-top: 30px;
  }
  @include sm {
    padding: 0px;
  }
  & > div {
    &:nth-child(1) {
      flex-direction: column;
      & > h1 {
        margin-bottom: 10px;
        @include sm {
          padding: 0px 20px;
          text-align: center;
        }
      }
      & > p {
        text-align: center;
        font-size: 16px;
        line-height: 20px;
        @include sm {
          padding: 0px 20px;
        }
      }
    }
  }
  .cp-form-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > div {
      margin-bottom: 20px;
      &:last-child,
      &:first-child {
        margin-bottom: 0px;
      }
    }
    .cp-profile-picture {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > div {
        &:nth-child(1) {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: calc(100% - 80px);
          & > svg {
            width: 20px;
            height: 20px;
            cursor: pointer;
            margin-left: 10px;
            &:hover {
              color: $primaryColor;
            }
          }
          & > label {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            max-width: calc(100% - 30px);
            width: max-content;
            margin: 0;
            cursor: pointer;
            & > svg {
              width: 20px;
              height: 20px;
              margin-right: 5px;
            }
            &:hover {
              color: $primaryColor;
            }
          }
        }
        &:nth-child(2) {
          width: 70px;
          max-height: 70px;
          & > img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    @include sm {
      .psl-footer-link {
        margin-bottom: 30px;
      }
      .cp-profile-picture {
        padding: 0px 20px;
        margin-top: 10px;
      }
      & > button {
        margin-top: 40px;
      }
      & > div {
        margin-bottom: 0px;
        &:first-child {
          padding-right: 5px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
// Profile Signup Page & Login Page CSS
.psl-root {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  .psl-form-div {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    & > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      & > div {
        margin-bottom: 15px;
      }
    }
    @include sm {
      margin-bottom: 30px;
      & > div > div {
        margin: 0px;
      }
      .psl-footer {
        & > button {
          margin: 40px 0px 0px;
        }
      }
    }
  }
  .psl-footer {
    justify-content: center;
    align-items: center;
  }
}
.psl-footer-link {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0px;
  & > a {
    color: $primaryColor;
    font-weight: $bold600;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  @include sm {
    padding: 0px 20px;
  }
}
// Product Feedback CSS
.pf-root {
  flex: 1;
  width: 100%;
  .pf-product-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 10px 0px;
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-left: 10px;
      & > p {
        font-size: 16px;
        font-family: $fontFamily;
        line-height: 19px;
        color: $blackColor33;
        margin: 0px;
        &:nth-child(1) {
          margin-bottom: 5px;
        }
        & > span {
          margin-right: 5px;
        }
      }
    }
    & > img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
  }
  .pf-form-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > div {
      margin-top: 15px;
      @include sm {
        margin-top: 0px;
      }
    }
    & > button {
      margin-top: 40px;
    }
    .feedback-rating-div {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      width: 100%;
      & > label {
        font-size: 13.5px;
        line-height: 19px;
        color: $blackColor33;
        font-weight: $light400;
        margin: 0px;
        margin-right: 10px;
        font-family: $fontFamily;
      }
      .error {
        color: $errorColor;
      }
      & > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 20px;
        & > svg {
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
      }
      @include sm {
        padding: 25px;
        background-color: $WhiteColor;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
      }
    }
  }
  @include sm {
    & > p {
      padding: 0px 20px;
      margin-bottom: 10px;
    }
    .pf-product-info {
      padding: 0px 20px;
    }
  }
}
// Profile Page CSS
.profile-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  .profile-page-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .profile-page-heading {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-bottom: 30px;
      & > h5 {
        margin-bottom: 10px;
      }
      & > p {
        margin: 0;
        padding: 0;
        font-weight: $medium500;
        font-size: 16px;
        line-height: 1;
      }
    }
    .organization-section {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 20px;
      & > p {
        font-size: 16px;
        margin-bottom: 20px;
        font-family: $fontFamily;
        font-weight: $light400;
        line-height: 19px;
        color: $blackColor33;
        margin-bottom: 10px;
      }
    }
    .share-button {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .invite-organization-text {
      margin: 0;
      & > p {
        margin: 0;
      }
    }
    .product-section {
      margin-bottom: 20px;
      width: 100%;
      max-width: 60%;
      @include down(1000px) {
        max-width: 80%;
      }
    }
    .profile-btns {
      justify-content: flex-start;
    }
    .trending-organization-container {
      .trending-organization-header {
        display: flex;
        flex-direction: column;
        width: 100%;
        & > p {
          margin-bottom: 10px;
        }
      }
    }
  }
  @include sm {
    .profile-page-content {
      height: calc(100% - 75px);
      overflow: auto;
      .profile-page-heading {
        padding: 20px;
        margin-bottom: 10px;
      }
      .organization-section {
        padding: 0px 20px;
      }
      .invite-organization-text {
        padding: 0 20px 20px;
      }
      .product-section {
        max-width: 100%;
        margin-bottom: 0px;
      }
      .profile-btns {
        margin: 40px 0px;
        justify-content: center;
      }
      .trending-organization-container {
        padding: 0px 20px;
      }
    }
  }
}
.product-list-li-offer-label {
  margin: 0;
}
.product-list-li-offer-label > span {
  margin-left: 10px;
  background-color: $errorColor;
  border-radius: 10px;
  width: max-content;
  padding: 2px 5px;
  font-size: 12px;
  color: $WhiteColor;
}
// Profile details CSS
.profile-details-root {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: 100%;
  .profile-detail-header {
    margin-bottom: 10px;
    & > h1 {
      text-align: left;
    }
  }
  .pd-avatar-div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 10px 10px 0 0;
    & > label {
      margin: 0px;
      & > div {
        width: 68px;
        height: 68px;
        background-color: $grayColorE8;
        cursor: pointer;
      }
    }
    .profile-picture-loading-div {
      width: 68px;
      height: 68px;
      border-radius: 50%;
      background-color: $grayColorE8;
    }
  }
  .category-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 5px;
    & > h4 {
      margin-bottom: 10px;
      color: $primaryColor;
    }
    .categories-root {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      & > p {
        text-align: justify;
        font-size: 16px;
        &:first-child {
          margin-bottom: 10px;
        }
      }
    }
  }
  .pd-form-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    & > div:not(:first-child) {
      margin-bottom: 15px;
    }
    & > div:first-child {
      margin: 20px 0px;
    }
    & > button {
      margin-top: 5px;
    }
  }
  .pd-progress-div {
    margin-top: 20px;
    & > p {
      margin-top: 10px;
    }
  }
  @include sm {
    & > div:nth-child(1) {
      padding: 20px 0 30px;
      height: calc(100% - 75px);
      overflow: auto;
    }
    .profile-detail-header {
      padding: 0px 20px;
    }
    .pd-form-div {
      align-items: center;
      & > div:not(:first-child) {
        margin-bottom: 0px;
      }
      & > div:first-child {
        padding-right: 5px;
      }
      & button {
        margin-top: 40px;
      }
    }
    .category-section {
      padding: 0px 20px;
    }
    .pd-progress-div {
      padding: 0px 20px;
    }
    .pd-avatar-div {
      width: 100%;
      justify-content: center;
      margin-top: 10px;
      & > label {
        & > div {
          width: 150px;
          height: 150px;
        }
      }
      .profile-picture-loading-div {
        width: 150px;
        height: 150px;
      }
    }
  }
  .profile-hading-root {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    .consumer-profile-delete-root {
      cursor: pointer;
      margin-top: 5px;
    }
    @include sm {
      padding: 0px 20px;
    }
    @include xs {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
    .delete-btn {
      background-color: #ff7578;
      border-color: #ff7578;
      &:hover {
        background-color: #ffffff;
        border-color: #ff7578;
        color: #ff7578;
        border: 1px solid;
      }
      & svg {
        width: 20px !important;
        height: 20px !important;
        margin-bottom: 1px !important;
      }
    }
  }
}
.delete-progress {
  color: #ffffff !important;
  &:hover {
    color: #28a745 !important;
  }
}
// Offers CSS
.offers-root {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 60px;
  .offers-circles {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      max-width: 80px;
      & > div {
        width: 68px;
        height: 68px;
        border-radius: 50%;
        background-color: $grayColorE8;
      }
      & > p {
        width: 100%;
        text-align: center;
        margin-top: 10px;
      }
    }
  }
  .offers-tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & > p {
      cursor: pointer;
      color: $grayColorC4;
      &:nth-child(1) {
        margin-right: 10px;
      }
    }
    .active {
      color: $primaryColor;
    }
  }
  .offers-ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
    .offers-li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $WhiteColor;
      padding: 15px 0px;
      border-bottom: 1px solid $grayColorD9;
      cursor: pointer;
      & > div {
        &:nth-child(1) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          width: calc(100% - 30px);
          & > p {
            &:nth-child(1) {
              font-weight: $medium500;
            }
            &:nth-child(2) {
              opacity: 0.5;
              font-weight: $light400;
              font-size: 16px;
            }
          }
        }
        &:nth-child(2) {
          & > svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
  .offers-unlock-msg {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    & > svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
  @include sm {
    padding: 0px;
    & > h1 {
      padding: 0px 20px;
      text-align: center;
    }
    .offers-circles,
    .offers-tabs {
      padding: 0px 20px;
    }
    .offers-unlock-msg {
      padding: 0px 20px 20px 20px;
    }
    .offers-ul {
      .offers-li {
        padding: 25px;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
        border-bottom: none;
      }
    }
  }
  @include xs {
    .offers-ul .offers-li > div:nth-child(1) > p:nth-child(2) {
      font-size: 14px;
    }
  }
}
// Survey page CSS
.survey-root {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  & > div:nth-child(1) {
    & > h1 {
      margin-bottom: 10px;
      text-align: left;
    }
    & > p {
      margin-bottom: 20px;
    }
    .batch-dropdown {
      margin-bottom: 15px;
    }
    .survey-form-div {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;
      & > div {
        margin-bottom: 20px;
      }
      .survey-switch {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > div {
          width: max-content;
          max-width: 110px;
        }
        & > p {
          font-size: 14px;
          color: $blackColor33;
        }
      }
    }
    .survey-footer {
      justify-content: flex-start;
    }
  }
  @include sm {
    & > div:nth-child(1) {
      padding-top: 20px;
      height: calc(100% - 75px);
      overflow: auto;
      & > h1,
      & > p {
        padding: 0px 20px;
      }
      .batch-dropdown {
        margin-bottom: 0px;
      }
      .survey-form-div {
        flex: 1;
        & > div {
          margin-bottom: 0px;
        }
        .survey-switch {
          padding: 25px;
          background-color: $WhiteColor;
          box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
          & > p {
            color: rgba(0, 0, 0, 0.3);
          }
        }
      }
      .survey-footer {
        margin: 40px 0px;
        justify-content: center;
      }
    }
  }
}
// survey history page css
.survey-history {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .tabs {
    width: max-content;
    height: max-content;
  }
  & > h1 {
    margin-bottom: 10px;
    text-align: left;
  }
  .survey-history-content {
    padding: 20px 0;
  }
  @include sm {
    .tabs {
      margin: 20px 20px 0;
      width: calc(100% - 40px) !important;
      .survey-history-tabs {
        width: 100%;
        & > div > div {
          & > button {
            min-width: 130px !important;
          }
        }
      }
    }
    .survey-history-content {
      padding: 20px;
      height: calc(100% - 68px - 75px);
      overflow: auto;
    }
  }
  .MuiAppBar-colorPrimary {
    color: #000;
    background-color: #fff;
  }
  .survey-history-tabs > div > span {
    background-color: #28a745 !important;
  }
  .MuiTab-textColorInherit.Mui-selected {
    color: #28a745;
    opacity: 1;
  }
}

.survey-score-container {
  & > label {
    font-size: 18px;
    line-height: 19px;
    color: $blackColor33;
    font-weight: 400;
    margin-bottom: 5px;
    font-family: $fontFamily;
    transform: scale(0.75);
  }
  & > div {
    display: flex;
    & > div {
      &:nth-child(1) {
        height: 40px;
        width: 40px;
        margin-right: 20px;
        & > svg {
          width: 100%;
          height: 100%;
          animation: heartBeat 1s;
        }
        .bad-review-icon {
          fill: var(--bad-color);
        }
        .ok-review-icon {
          fill: var(--ok-color);
        }
        .good-review-icon {
          fill: var(--good-color);
        }
        .great-review-icon {
          fill: var(--great-color);
        }
      }
      &:nth-child(2) {
        position: relative;
        max-width: 360px;
        width: 100%;
        height: 17px;
        display: flex;
        align-items: center;
        & > svg {
          width: 100%;
          height: 100%;
          transition: linear 0.5s;
        }
        & > input {
          appearance: none;
          -webkit-appearance: none;
          width: 100%;
          position: absolute;
          left: 0;
          top: 50%;
          margin: 0;
          padding: 0;
          transform: translateY(-50%);
          background: none;
          transition: linear 0.5s;
          &:focus {
            outline: 0;
          }
          &::-webkit-slider-thumb {
            width: 26px;
            height: 26px;
            -webkit-appearance: none;
            cursor: pointer;
            border-radius: 50%;
            border: 6px solid $WhiteColor;
            box-shadow: 0px 0px 6px 0px $grayColor02;
            z-index: 2;
            position: relative;
            margin-top: -3px;
          }
        }
        &::after {
          content: "";
          position: absolute;
          box-shadow: 99px 31px 0px 1px var(--track-color), 183px 31px 0px 1px var(--track-color),
            267px 31px 0px 1px var(--track-color), 351px 31px 0px 1px var(--track-color);
          left: 0;
          height: 6px;
        }
      }
      &:nth-child(3) {
        margin-left: 20px;
      }
    }
  }
  .bad input[type="range"]::-webkit-slider-thumb {
    background: var(--bad-color);
  }

  .ok input[type="range"]::-webkit-slider-thumb {
    background: var(--ok-color);
  }

  .good input[type="range"]::-webkit-slider-thumb {
    background: var(--good-color);
  }

  .great input[type="range"]::-webkit-slider-thumb {
    background: var(--great-color);
  }

  .bad .range:after {
    box-shadow: 99px 31px 0px 1px var(--bad-color), 183px 31px 0px 1px var(--track-color),
      267px 31px 0px 1px var(--track-color), 351px 31px 0px 1px var(--track-color);
  }

  .ok .range:after {
    box-shadow: 99px 31px 0px 1px var(--track-color), 183px 31px 0px 1px var(--ok-color),
      267px 31px 0px 1px var(--track-color), 351px 31px 0px 1px var(--track-color);
  }

  .good .range:after {
    box-shadow: 99px 31px 0px 1px var(--track-color), 183px 31px 0px 1px var(--track-color),
      267px 31px 0px 1px var(--good-color), 351px 31px 0px 1px var(--track-color);
  }

  .great .range:after {
    box-shadow: 99px 31px 0px 1px var(--track-color), 183px 31px 0px 1px var(--track-color),
      267px 31px 0px 1px var(--track-color), 351px 31px 0px 1px var(--great-color);
  }
}

@-webkit-keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.org_offer_badge {
  background-color: $errorColor;
  color: $WhiteColor;
  border-radius: 15px;
  padding: 3px 8px;
  font-size: 14px;
  line-height: 18px;
  margin-left: 10px;
}

.profile-feedback-page {
  @include sm {
    & > h1 {
      padding: 20px 20px 0;
    }
    & > button {
      margin: 0 20px 20px;
    }
    & > div {
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        padding: 0 20px;
      }
      &:nth-child(4) {
        padding-top: 20px;
      }
    }
  }
  @include xs {
    & > div {
      &:nth-child(5) {
        flex-wrap: wrap;
      }
    }
  }
}

.psl-form-master-toggle {
  flex-direction: row !important;
}

.psl-footer-action-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  & > button {
    &:nth-child(1) {
      margin-right: 30px;
    }
  }
  @include sm {
    margin-top: 20px !important;
  }
  @include xs {
    flex-direction: column-reverse;
    & > button {
      &:nth-child(1) {
        margin-right: 0;
        margin-top: 15px;
      }
    }
  }
}

.practitioner-files {
  & > div {
    & > label {
      cursor: pointer;
    }
    & > svg {
      cursor: pointer;
    }
    & > img {
      object-fit: contain;
    }
  }
}

.user-profile-edit-label {
  position: relative;
  margin-right: 10px;
  & > span {
    position: absolute;
    top: -5px;
    right: -5px;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    background-color: $primaryColor;
    width: 25px;
    height: 25px;
    display: none;
    align-items: center;
    justify-content: center;
    & > svg {
      width: 15px;
      height: 15px;
      color: $WhiteColor;
    }
  }
  &:hover {
    & > span {
      display: flex;
    }
  }
  @include sm {
    & > span {
      display: flex;
      top: 10px;
      right: 10px;
    }
  }
}

.createPost-user-profile.user-profile-edit-label {
  margin-right: 0;
  & > span {
    top: -10px;
    right: 5px;
  }
}
